/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import {
  getLicenseStatus,
  addCommentsDraftAgreement,
  getCommentsDraftAgreement,
  getcurrentStatus,
  sendNotify,
  compileAgreement,
  resolveComments,
  unResolveComment,
  putNextStatus,
  getFormData,
  updateAgreementFile,
} from "../../api/currentApplications";
import images from "../../images/print-logo.png";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import UploadDraftLoading from "../loaders/UploadDraftLoading";
import { API_URL, File, REACT_APP_URL, REACT_APP_NS_risk_finance_fee_original } from "../../api";
import CompileComments2 from "./CompileComments2";
import { Netsuite } from "../../schema/NetSuiteSchema";
import checkFileName from "../../utils/checkSpecialCharacter";
import CompiledPayment from "../compiledPayment/CompiledPayment";
import { getNetsuiteInitialValue, netSuiteUpsertRecords } from "../../api/Netsuite";
import FieldAll from "../ui/FieldAll";
import LicenseUpdateComment from "./LicenseUpdateComment";
import { useAtoms } from "../../hooks/useAtoms";
import { useUser } from "../../hooks/useUser";
import Select from "react-select";
import { createContact, getContactsByCompanyId } from "../../api/crm";
import { useApplication } from "../../hooks/useApplication";
import DashBoardLoading from "../loaders/DashBoardLoading";
import { socket } from "../../utils/socket-io";

export default function ValidateFee(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const user = useUser() || {};
  const application = useApplication();
  const { roleName = "", contact = {}, id: contactRoleId } = user;
  const [compileValue, setCompileValue] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resolvedComment, setResolvedComments] = useState([]);
  const [showAll, setShowAll] = React.useState([]);
  const [editorState, setEditorState] = useState();
  const [showInputTag, setShowInputTag] = useState(false);
  const [commentsText, setCommentsText] = useState("");
  const [complieData1, setComplieData1] = useState(false);
  const [complieData2, setComplieData2] = useState(false);
  const [complieData3, setComplieData3] = useState(false);
  const [complieData4, setComplieData4] = useState(false);
  const [disable, setDisable] = useState(false);
  const [riskFile, setRiskFile] = useState(false);
  const [riskFinanceFee, setReskFinanceFee] = useState(false);
  const [allComments, setAllComments] = useState([]);
  const [allCommentsApplicant, setAllCommentsApplicant] = useState([]);
  const [laodSubmit, setLoadSubmit] = useState(false);
  const [file, setFile] = useState([]);
  const [ID, setID] = useState();
  const [stage, setStage] = useState("");
  const [qId, setQid] = useState(0);
  const [notification, setNotification] = useState({});
  const [singleApprover, setSingleApprover] = useState(false);
  const [singlehntb, setSingleHntb] = useState(false);
  const [singlehntbcommt, setSingleHntbcommt] = useState(false);
  const [singleApplicantcommt, setSingleApplicantcommt] = useState(false);
  const [characterError, setCharacterError] = useState({
    Certificates_of_Insurance_1: false,
    Certificates_of_Insurance_2: false,
    Certificates_of_Insurance_3: false,
    Certificates_of_Insurance_4: false,
    Certificates_of_Insurance_1Name: "",
    Certificates_of_Insurance_2Name: "",
    Certificates_of_Insurance_3Name: "",
    Certificates_of_Insurance_4Name: "",
  });
  const [arrayOfnesuite, setArrayOfnesuite] = useState([]);

  let { ItemId } = props;
  const [compileSignedValue, setCompileSignedValue] = useState([]);
  const { name: SignerName, email: SignerEmail, status: CurrentStatus } = compileSignedValue || {};
  const SignedDate = compileSignedValue ? (compileSignedValue.signedAt ? new Date(compileSignedValue.signedAt).toString().slice(0, 15) : "") : "";

  const [me, setMe] = useState(true);
  const [applicationDetails, setApplicationDetails] = useState("");
  const applicationID = ItemId;
  localStorage.setItem("applicationId", applicationID);
  const [value, setValue] = useState({});
  const [step, setCurrentStep] = useState("");
  const [showEditValue, setShowEditValue] = useState(true);
  const [event2validatedOn, setEvent2validatedOn] = useState("");
  const [oldApplication, setOldApplication] = useState(false);
  const { setHideNavigation } = useAtoms();
  const [records, setRecords] = React.useState([]);
  const [netsuiteField, setNetsuiteValue] = React.useState({
    INSURANCE: {},
    AGREEMENT: {},
  });

  useEffect(() => {
    Netsuite.sections.forEach((section) => {
      section.questions.forEach((val) => {
        if (val.validateInsurance === "true") {
          setArrayOfnesuite((initialValue) => [...initialValue, val]);
        }
      });
    });
  }, [Netsuite]);

  useEffect(() => {
    const array = arrayOfnesuite.map((section) => section?.recordName);
    setRecords(array);
  }, [arrayOfnesuite]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const promises = records.map(async (record) => {
          const res = await getNetsuiteInitialValue(ItemId, record);
          const response = res?.data?.data;
          return { [record]: response };
        });
        const results = await Promise.all(promises);
        const mergedResults = results.reduce((acc, result) => ({ ...acc, ...result }), {});

        setNetsuiteValue((initialValue) => ({
          ...initialValue,
          ...mergedResults,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [records, ItemId, getNetsuiteInitialValue]);

  const handleNetSuiteChange = (data, event, recordName, column) => {
    let item = 0;
    if (column?.itemId) {
      item = column.itemId;
    }
    const { keyName = "" } = data;
    const { value } = event.target;
    const acceptedType1 = ["text", "date", "number", "dropdown"];
    const acceptedType2 = ["textarea"];
    const type = data.type;
    if (acceptedType1.includes(type) && item === 0) {
      setNetsuiteValue((initialValue) => ({
        ...initialValue,
        [recordName]: { ...initialValue[recordName], [keyName]: value },
      }));
    }
    if (acceptedType2.includes(type) && item === 0) {
      if (recordName === "OPPORTUNITY" && keyName === "scopeofwork") {
        setNetsuiteValue((initialValue) => ({
          ...initialValue,
          OPPORTUNITY: { ...initialValue.OPPORTUNITY, scopeofwork: value },
          AGREEMENT: { ...initialValue.AGREEMENT, scopeofwork: value },
          UTILITY: { ...initialValue.UTILITY, wirescablesunits: value },
        }));
      } else {
        setNetsuiteValue((initialValue) => ({
          ...initialValue,
          [recordName]: { ...initialValue[recordName], [keyName]: value },
        }));
      }
    }
  };

  const setNavigationContent = () => {
    try {
      setHideNavigation(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setNavigationContent();
  }, []);

  useEffect(async () => {
    setLoading(true);
    const object = {
      applicationID: ItemId,
      signerEmail: ["Test"],
      agreementType: "CompiledAgreement",
    };
    let response = await sendNotify(object);
    setCompileSignedValue(response.data.responseData);

    let responsee = await getLicenseStatus(ItemId);
    setCompileValue(responsee.data.data);

    let value = responsee.data.data.compiled && responsee.data.data.compiled.filter((data) => data.action === "Sign and Paid");
    setReskFinanceFee(value[0] && value[0].riskFinanceFee === "true" ? true : false);
    setLoading(false);
  }, []);
  useEffect(async () => {
    socket.emit("chat send");
    socket.on("chat send", (msg) => {
      msg.action === "Uploaded" && setAllComments((prev) => (prev ? [...prev, msg] : [msg]));
      msg.action === "Sign and Paid" && setAllCommentsApplicant((prev) => (prev ? [...prev, msg] : [msg]));
    });
    let responseComment = await getCommentsDraftAgreement(ItemId);

    responseComment.data.data.compiled.map((value) => (value.action === "Uploaded" ? setAllComments(value.comment && value.comment) : null));
    responseComment.data.data.compiled.map((value) => (value.action === "Sign and Paid" ? setAllCommentsApplicant(value.comment && value.comment) : null));
  }, []);
  useEffect(async () => {
    socket.emit("chat send");
    socket.on("chat send", (msg) => {
      msg.action === "Sign and Paid" && setAllCommentsApplicant((prev) => (prev ? [...prev, msg] : [msg]));
    });
    let responseComment = await getCommentsDraftAgreement(ItemId);
    responseComment.data.data.compiled.map((value) => (value.action === "Uploaded" ? setAllComments(value.comment && value.comment) : null));
    responseComment.data.data.compiled.map((value) => (value.action === "Sign and Paid" ? setAllCommentsApplicant(value.comment && value.comment) : null));
    return () => {
      setAllCommentsApplicant({});
    };
  }, []);

  let Riskfile = JSON.parse(localStorage.getItem("Risk_Finance"));
  let feevalue = JSON.parse(localStorage.getItem("Risk_Finance_file"));
  useEffect(() => {
    feevalue = JSON.parse(localStorage.getItem("Risk_Finance"));
    Riskfile = JSON.parse(localStorage.getItem("Risk_Finance_file"));
    if (feevalue) {
      setDisable(true);
    } else if (Riskfile) {
      setRiskFile(true);
    }
  }, [disable, riskFile]);
  useEffect(async () => {
    let response = await getcurrentStatus(ItemId);

    setCurrentStep(response.data.data);
  }, []);

  useEffect(async () => {
    let response = await getLicenseStatus(ItemId);
    setValue(response.data.data);
  }, []);

  useEffect(async () => {
    setEvent2validatedOn(application?.event2validatedOn ? new Date(application?.event2validatedOn)?.toString()?.slice(0, 15) || "" : "");

    if (!application?.event2validatedOn && application.applicationStage > 18) {
      setOldApplication(true);
    }

    const { supplement = {} } = application;
    if (supplement.supplement) {
      setArrayOfnesuite((value) => {
        return value.map((each) => {
          if (each.recordName === "AGREEMENT") {
            return { ...each, hide: true };
          }
          return each;
        });
      });
    }
  }, []);

  useEffect(async () => {
    let details = await getFormData(ItemId);
    setApplicationDetails(details.data);
    let newRes = await getcurrentStatus(ItemId);
    setLoading(false);
    setStage(newRes.data.data);
  }, []);

  let { compiled: compiledValue } = value || [];

  let compiledValueObject = (compiledValue && compiledValue[0]) || {};
  let { formData: compiledValueObjectFormData } = compiledValueObject || {};

  let paymentInstructionFile = compiledValueObjectFormData && compiledValueObjectFormData[1];
  let paymentInstructionsfileName = (paymentInstructionFile && paymentInstructionFile.fileNameOriginal) || "";
  let paymentInstructionsfileNameUpdated = (paymentInstructionFile && paymentInstructionFile.fileNameUpdated) || "";

  let { compiled, transactionId, totalAmount, downloadSignedDocuments1 } = compileValue || [];
  totalAmount = totalAmount ? totalAmount / 100 : "";

  let compiles0 = (compiled && compiled[0]) || [];
  let { action, date, formData } = compiles0 || {};
  let filedata = formData && formData[0];
  let { fileNameOriginal, fileNameUpdated, signedDocumentsEnvelopeId = [] } = filedata || "";
  let compiles1 = (compiled && compiled[1]) || [];

  let { date: date1, formData: formData1 } = compiles1 || {};
  let filedata1;
  let filedata2;
  let filedata3;
  let filedata4;
  if (riskFinanceFee) {
    filedata1 = formData1 && formData1[0];
    filedata2 = formData1 && formData1[1];
  } else {
    filedata1 = formData1 && formData1[0];
    filedata2 = formData1 && formData1[1];
    filedata3 = formData1 && formData1[2];
    filedata4 = formData1 && formData1[3];
  }

  let { fileNameOriginal: fileNameOriginal1, fileNameUpdated: fileNameUpdated1 } = filedata1 || "";
  let { fileNameOriginal: fileNameOriginal2, fileNameUpdated: fileNameUpdated2 } = filedata2 || "";
  let { fileNameOriginal: fileNameOriginal3, fileName: fileName3, fileNameUpdated: fileNameUpdated3 } = filedata3 || "";
  let { fileNameOriginal: fileNameOriginal4, fileName: fileName4, fileNameUpdated: fileNameUpdated4 } = filedata4 || "";

  const signAndPaid = compileValue.compiled && compileValue.compiled.filter((data) => data.action === "Sign and Paid");

  if (showInputTag) {
    var onEditFormSubmit = (data) => {
      if (!characterError.Certificates_of_Insurance_1 && !characterError.Certificates_of_Insurance_2 && !characterError.Certificates_of_Insurance_3 && !characterError.Certificates_of_Insurance_4) {
        saveCompileFee(data);
      }
    };
  }

  const saveCompileFee = async () => {
    setLoadSubmit(true);

    if (!complieData1 && !complieData2 && !complieData3 && !complieData4 && !riskFinanceFee) {
      window.location.reload();
    }

    const formData = new FormData();
    formData.append("action", "Sign and Paid");
    formData.append("applicationID", ItemId);
    formData.append("type", "edit");
    formData.append("Risk_Finance", riskFinanceFee);
    formData.append("role", roleName);
    formData.append("date", new Date());

    let licenseData;
    if (!riskFinanceFee) {
      licenseData = [
        filedata1 && filedata1,
        filedata2 && filedata2,
        filedata3 && filedata3,
        filedata4 && filedata4,
        {
          esignatureRequired: true,
        },
      ];
    }
    if (riskFinanceFee) {
      licenseData = [
        filedata1 && filedata1,
        filedata2 && filedata2,
        {
          esignatureRequired: true,
        },
      ];
    }
    let license_file_1;
    let filename1;
    let license_file_2;
    let filename2;
    let license_file_3;
    let filename3;
    let license_file_4;
    let filename4;
    if (document.getElementById("Certificates_of_Insurance_1").files[0]) {
      license_file_1 = document.getElementById("Certificates_of_Insurance_1").files[0];
      filename1 = license_file_1.name || "";
      formData.append("Certificates_of_Insurance_1", license_file_1, filename1);
      licenseData[0] = {
        fileLabel: document.getElementById("Certificates_of_Insurance_1").name,
        fileNameOriginal: filename1,
        fileName: "",
      };
    }

    if (document.getElementById("Certificates_of_Insurance_2").files[0]) {
      license_file_2 = document.getElementById("Certificates_of_Insurance_2").files[0];
      filename2 = license_file_2.name || "";
      formData.append("Certificates_of_Insurance_2", license_file_2, filename2);
      licenseData[1] = {
        fileLabel: document.getElementById("Certificates_of_Insurance_2").name,
        fileNameOriginal: filename2,
        fileName: "",
      };
    }

    if (document.getElementById("Certificates_of_Insurance_3").files[0] && !riskFinanceFee) {
      license_file_3 = document.getElementById("Certificates_of_Insurance_3").files[0];
      filename3 = license_file_3.name || "";
      formData.append("Certificates_of_Insurance_3", license_file_3, filename3);
      licenseData[2] = {
        fileLabel: document.getElementById("Certificates_of_Insurance_3").name,
        fileNameOriginal: filename3,
        fileName: "",
      };
    }

    if (document.getElementById("Certificates_of_Insurance_4").files[0] && !riskFinanceFee) {
      license_file_4 = document.getElementById("Certificates_of_Insurance_4").files[0];
      filename4 = license_file_4.name || "";
      formData.append("Certificates_of_Insurance_4", license_file_4, filename4);
      licenseData[3] = {
        fileLabel: document.getElementById("Certificates_of_Insurance_4").name,
        fileNameOriginal: filename4,
        fileName: "",
      };
    }

    const fileNames = licenseData.map((data) => data.fileNameOriginal);
    fileNames.pop();
    const FileNameResult = checkFileName(fileNames);
    if (FileNameResult === true) {
      formData.append("licenseData", JSON.stringify(licenseData));
      await compileAgreement(formData);
      window.location.reload();
    } else {
      alert(`Please Change the Names Of This File Before Submiting ${FileNameResult.toString()}`);
      window.location.reload();
    }
  };

  const resolveCommet = async (qId) => {
    let data = {
      applicationID: props.applicationID,
      reviewType: "engineeringComments",
      questionID: qId,
    };
    let res = await unResolveComment(data);
    if (res) {
      window.location.reload();
    }
  };

  const commentsResolve = async (data) => {
    let value = {
      questionID: data.id,
      applicationID: props.applicationID,
      reviewType: "engineeringComments",
      resolvedBy: contact.email,
    };
    socket.emit("chat message", value);
    let dat = await resolveComments(value);
    if (dat) {
      window.location.reload();
    }
  };

  const showInput = () => {
    setShowInputTag(true);
  };
  const deleteItem = () => {
    setComplieData1(true);
  };
  const deleteItem1 = () => {
    setComplieData2(true);
  };
  const deleteItem3 = () => {
    setComplieData3(true);
  };
  const deleteItem4 = () => {
    setComplieData4(true);
  };
  // -------------edit comment----------

  const UpdateLicense = async () => {
    const license_file = document.getElementById("License_Agreement").files[0];
    const formData = new FormData();
    formData.append("applicationID", ItemId);
    formData.append("license_file", license_file);
    try {
      await updateAgreementFile(formData);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  const editvalue = () => {
    setShowEditValue(false);
  };

  const putCommentEditforAgreement = () => {
    if (commentsText.length) {
      const date = new Date();
      const formData = {
        id: user.id,
        name: user.contact.name,
        role: user.role.role || user.roleName,
        email: user.contact.email,
        roleName: user.roleName,
      };
      const data = {
        questionID: ID,
        agreementType: "compiled",
        comment: commentsText,
        timeStamp: date.toDateString(),
        from: JSON.stringify(formData),
        applicationID: ItemId,
        files: file,
        action: "Uploaded",
      };
      socket.emit("chat message", data);

      addCommentsDraftAgreement(data)
        .then(({ data }) => {
          setEditorState("");
          setCommentsText("");
          if ((!uploaded.comment || uploaded.resolved === true) && roleName === "Engineering Representative") {
            // UnresolveCompileCommentEdit();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // toast.error("Please add comment");
      alert("Please add comment");
    }
  };

  const UnresolveCompileCommentEdit = async () => {
    const data = {
      applicationID: ItemId,
      action: "Sign and Paid",
      agreementType: "compiled",
      unresolveComment: true,
    };
    let comm = await addCommentsDraftAgreement(data);
    if (comm) {
      setEditorState("");
      setCommentsText("");
    }
  };
  const resolveCompileCommentEdit = async () => {
    setLoading(true);
    const data = {
      applicationID: ItemId,
      action: "Uploaded",
      agreementType: "compiled",
      resolveComment: true,
    };
    await addCommentsDraftAgreement(data);
    setLoading(false);
    window.location.reload();
  };
  function handleComment(comment, id) {
    setCommentsText(comment);
    setID(id);
  }
  function handleCommentEdit(comment, id) {
    setCommentsText(comment);
    setID(id);
  }

  // -------------end comment----------
  let resolveCommentId = resolvedComment.map((rID) => rID.questionID);

  const signatureFunction = async () => {
    setLoading(true);
    const { email, name } = contact;
    let signValues = {
      signerName: name,
      signerEmail: email,
    };

    if (me) {
      let data = {
        applicationID: ItemId,
        signerName: name,
        signerEmail: [email],
        signBy: "me",
        agreementType: "CompiledAgreement",
      };
      await sendNotify(data);
    }
    await putNextStatus(ItemId, 1);
    props.signingFunction(signValues);
  };

  const sendEmailNotification = async ({ name, email }, resend = false) => {
    let linkUrl = REACT_APP_URL + `/compiledAgreement/${ItemId}`;
    let data = {
      applicationID: ItemId,
      signerName: name,
      signerEmail: [email],
      userId: contactRoleId,
      LinkUrl: linkUrl,
      status: "waiting",
      agreementType: "CompiledAgreement",
      resend: resend,
    };
    await sendNotify(data);
    await putNextStatus(ItemId, 1);
    window.location.reload();
  };

  const putComment = () => {
    if (commentsText.length) {
      const date = new Date();
      const formData = {
        id: user.id,
        name: user.contact.name,
        role: user.role.role || user.roleName,
        email: user.contact.email,
        roleName: user.roleName,
      };
      const data = {
        questionID: ID,
        agreementType: "compiled",
        comment: commentsText,
        timeStamp: date.toDateString(),
        from: JSON.stringify(formData),
        applicationID: ItemId,
        files: file,
        action: "Sign and Paid",
      };
      socket.emit("chat message", data);
      addCommentsDraftAgreement(data)
        .then(({ data }) => {
          setEditorState("");
          setCommentsText("");
          if ((!signAndPaid.comment || signAndPaid.resolved === true) && roleName === "Approving Representative") {
            UnresolveCompileComment();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // toast.error("Please add comment");
      alert("Please add comment");
    }
  };
  const UnresolveCompileComment = async () => {
    const data = {
      applicationID: ItemId,
      action: "Sign and Paid",
      agreementType: "compiled",
      unresolveComment: true,
    };
    let comm = await addCommentsDraftAgreement(data);
    if (comm) {
      setEditorState("");
      setCommentsText("");
    }
  };

  const validateFee = async () => {
    setLoading(true);
    const data = {
      applicationID: ItemId,
      action: "Sign and Paid",
      agreementType: "compiled",
      validated: true,
    };
    await addCommentsDraftAgreement(data);
    await putNextStatus(ItemId, 1);
    setLoading(false);
    const { AGREEMENT, INSURANCE } = netsuiteField;
    await netSuiteUpsertRecords(ItemId, "INSURANCE", INSURANCE);
    await netSuiteUpsertRecords(ItemId, "AGREEMENT", AGREEMENT);
    window.location.reload();
  };

  const resolveCompileComment = async () => {
    setLoading(true);
    const data = {
      applicationID: ItemId,
      action: "Sign and Paid",
      agreementType: "compiled",
      resolveComment: true,
    };
    await addCommentsDraftAgreement(data);
    setLoading(false);
    window.location.reload();
  };

  const sendNotification = async (commentType, notificationTo) => {
    setNotification({});
    const object = {
      applicationID: ItemId,
      signerEmail: ["Test"],
      agreementType: "CompiledAgreement",
      notificationTo: notificationTo,
      customerID: applicationDetails.createdby,
      LinkUrl: process.env.REACT_APP_URL + window.location.pathname,
    };
    await sendNotify(object);

    setNotification(notificationTo);
    setSingleHntb(false);
    setSingleApprover(false);
    setSingleHntbcommt(false);
    setSingleApplicantcommt(false);
  };
  const paymentValue = signAndPaid && signAndPaid[0] && signAndPaid[0].Payment ? signAndPaid[0].Payment : stage.currentStep >= 18 ? true : false;
  let resolvedValue = signAndPaid && signAndPaid[0] && signAndPaid[0].resolved ? signAndPaid[0].resolved : false;

  let checkCommentsLength2 = signAndPaid?.[0]?.comment?.length || 0;

  const uploaded = compileValue.compiled && compileValue.compiled.filter((data) => data.action === "Uploaded");

  const checkCommentsLength = uploaded?.[0]?.comment?.length || 0;

  let checkCommentsAndResolved = true;
  let checkCommentsAndResolved2 = true;

  let resolvedValues = uploaded?.[0]?.resolved || false;

  const validated = signAndPaid && signAndPaid[0] && signAndPaid[0].validated ? signAndPaid[0].validated : false;

  if (checkCommentsLength > 0 && resolvedValues === false) {
    checkCommentsAndResolved = false;
  }

  if (checkCommentsLength2 > 0 && resolvedValue === false) {
    checkCommentsAndResolved2 = false;
  }

  const commentResult = [];

  allComments &&
    allComments.map((val) => {
      let customer = JSON.parse(val.from);
      return commentResult.push(customer.roleName);
    });

  let EngineeringComment = commentResult && commentResult.filter((d) => d === "Engineering Representative");

  return loading ? (
    <>
      <UploadDraftLoading />
      <UploadDraftLoading />
    </>
  ) : (
    <div>
      <div className="text-center py-3 imagecon ">
        <img src={images} alt="Image" className="ImagePrint p-0" />
      </div>
      <div className="container mt-4">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div id="files-upload" className="">
              <div className="inner-form card mb-4 position-relative comment-card print">
                <div className="card-header border-bottom">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="mb-0">Compiled License Agreement</h2>
                    <small className="text-muted">By HNTB</small>
                  </div>
                </div>
                <div className="card-body pb-0">
                  {roleName == "applicant" && allComments && allComments.length > 0 && resolvedValues === false && (
                    <>
                      {notification == "engineeringRepresentatives" ? (
                        <div className="position-absolute notifyBtn">
                          <a className="btn btn-success m-0 disabled">
                            <small>
                              <i className="fas fa-check-double"></i> Notification Sent
                            </small>
                          </a>
                        </div>
                      ) : (
                        <>
                          {singlehntbcommt === true ? (
                            <>
                              <div className="position-absolute notifyBtn">
                                <a className="btn btn-outline-success m-0 disabled">
                                  <small>
                                    <i className="far fa-check"></i> Notification Sent
                                  </small>
                                </a>
                              </div>
                            </>
                          ) : (
                            <div className="position-absolute notifyBtn">
                              <a onClick={() => sendNotification("applicant", "engineeringRepresentatives", setSingleHntbcommt(true))} className="btn btn-primary text-white m-0">
                                <small>Notify HNTB</small>
                              </a>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {roleName == "Engineering Representative" && EngineeringComment && EngineeringComment.length > 0 && resolvedValues === false && (
                    <>
                      {notification == "applicant" ? (
                        <div className="position-absolute notifyBtn">
                          <a className="btn btn-success m-0 disabled">
                            <small>
                              <i className="fas fa-check-double"></i> Notification Sent
                            </small>
                          </a>
                        </div>
                      ) : (
                        <>
                          {singleApplicantcommt === true ? (
                            <div className="position-absolute notifyBtn">
                              <a className="btn btn-outline-success m-0 disabled">
                                <small>
                                  <i className="far fa-check"></i> Notification Sent
                                </small>
                              </a>
                            </div>
                          ) : (
                            <div className="position-absolute notifyBtn">
                              <a onClick={() => sendNotification("engineeringRepresentatives", "applicant", setSingleApplicantcommt(true))} className="btn btn-primary text-white m-0">
                                <small>Notify Applicant</small>
                              </a>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}

                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <label htmlFor="license-agreement" className="form-label">
                      Uploaded License Agreement
                    </label>
                    <LicenseUpdateComment
                      applicationID={ItemId}
                      fileNameOriginal={fileNameOriginal}
                      signedDocumentsEnvelopeId={signedDocumentsEnvelopeId}
                      fileNameUpdated={fileNameUpdated}
                      stage={stage.currentStep}
                      resolvedValues={resolvedValues}
                      handleCommentEdit={handleCommentEdit}
                      setFile={setFile}
                      putCommentEdit={putCommentEditforAgreement}
                      resolveCompileComment={resolveCompileCommentEdit}
                      UnresolveCompileCommentEdit={UnresolveCompileCommentEdit}
                      handleComment={handleComment}
                    />
                    {(validated === false &&
                      showEditValue &&
                      resolvedValues === false &&
                      (roleName === "Approving Representative" || roleName === "Engineering Representative") &&
                      allComments &&
                      allComments.length > 0) ||
                    (validated === false && resolvedValues === false && roleName === "applicant") ? (
                      <button
                        className="btn-outline-dark btn-sm 
                      btn text-dark comment-btn
                       position-relative"
                        data-bs-toggle="modal"
                        data-bs-target="#comment-modaledit"
                      >
                        {(allComments && allComments.length === 0) || allComments === undefined ? "Start New Comment" : "Comments"}
                      </button>
                    ) : null}
                    {validated === false &&
                    resolvedValues === true &&
                    (roleName === "Engineering Representative" || roleName === "Approving Representative" || roleName === "applicant") &&
                    allComments &&
                    allComments.length > 0 ? (
                      <button
                        className="btn-outline-success btn-sm 
                      btn text-success comment-btn
                       position-relative"
                        data-bs-toggle="modal"
                        data-bs-target="#comment-modaledit"
                      >
                        <i className="far fa-check text-success"></i> Resolved
                      </button>
                    ) : (
                      " "
                    )}
                  </div>

                  <div className="card bg-secondary border-0">
                    <div className="card-body p-3">
                      {showEditValue && (
                        <>
                          <div className="d-flex align-items-center mb-4">
                            <div className="flex-shrink-0">
                              <i className="fal fa-file-pdf"></i>
                            </div>
                            <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal}</div>
                            <div className="flex-shrink-0">
                              <a
                                href={
                                  API_URL +
                                  File +
                                  (signedDocumentsEnvelopeId && signedDocumentsEnvelopeId.length === 1 && downloadSignedDocuments1 ? signedDocumentsEnvelopeId.length + "_" : "") +
                                  fileNameUpdated
                                }
                                target="_blank"
                                className="btn btn-link"
                              >
                                View
                              </a>
                              <a
                                href={
                                  API_URL +
                                  File +
                                  (signedDocumentsEnvelopeId && signedDocumentsEnvelopeId.length === 1 && downloadSignedDocuments1 ? signedDocumentsEnvelopeId.length + "_" : "") +
                                  fileNameUpdated
                                }
                                target="_blank"
                                className="btn btn-link"
                              >
                                Download
                              </a>
                            </div>
                          </div>
                          {resolvedValues === false && roleName === "Engineering Representative" && allComments && allComments.length > 0 && (
                            <button
                              className="btn btn-link pb-0"
                              style={{
                                textDecoration: "underline",
                                pointerEvents: "fill",
                              }}
                              onClick={() => {
                                editvalue();
                              }}
                            >
                              <i className="far fa-pen"></i> Edit
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  {!showEditValue && (
                    <>
                      <div className="mb-4">
                        <input
                          type="file"
                          className="form-control"
                          id="License_Agreement"
                          accept=".pdf"
                          disabled={roleName !== "Engineering Representative"}
                          {...register("License_Agreement", {
                            required: true,
                            onChange: (e) => {
                              const fileName = e.target.files[0].name;
                              if (!checkFileName(fileName)) {
                                setCharacterError((data) => ({
                                  ...data,
                                  License_Agreement: true,
                                  License_AgreementName: fileName,
                                }));
                              } else {
                                setCharacterError((data) => ({
                                  ...data,
                                  License_Agreement: false,
                                  License_AgreementName: "",
                                }));
                              }
                            },
                          })}
                        />
                        <span className="text-danger">
                          <span>
                            {errors.License_Agreement && errors.License_Agreement.type === "required" && <span>This field is required</span>}
                            {characterError.License_Agreement && <span className="text-sm">Remove special characters from file name, allowed special characters are - and _</span>}
                          </span>
                        </span>
                      </div>
                      <button
                        className="btn btn-link"
                        style={{
                          textDecoration: "underline",
                          pointerEvents: "fill",
                        }}
                        onClick={() => {
                          UpdateLicense();
                        }}
                      >
                        <i className="far fa-check"></i> Save
                      </button>
                      <a
                        className="btn btn-link text-warning"
                        style={{
                          textDecoration: "underline",
                          pointerEvents: "fill",
                        }}
                        href={REACT_APP_URL + "/compiledAgreement/" + ItemId}
                      >
                        <i className="far fa-ban"></i> Cancel
                      </a>
                    </>
                  )}
                </div>
                <div className="card-footer py-0 justify-content-center bg-success">
                  <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                    <span className="d-flex justify-content-between align-items-center">
                      <span>
                        <i className="fal fa-check"></i> {action}{" "}
                      </span>
                      <span>By HNTB Representative on {date && <small> {date ? date.slice(0, 15) : ""}</small>}</span>
                    </span>
                  </a>
                </div>
              </div>
              <div className={`${resolvedValue === false ? "card mb-4 position-relative comment-card border-primary print" : "card mb-4 position-relative comment-card print"}`}>
                <form onSubmit={handleSubmit(onEditFormSubmit)}>
                  {roleName == "applicant" && !paymentValue && allCommentsApplicant && allCommentsApplicant.length > 0 && resolvedValue === false && (
                    <>
                      {notification == "approvingRepresentatives" ? (
                        <div className="position-absolute notifyBtn">
                          <a className="btn btn-success  m-0 disabled">
                            <small>
                              <i className="fas fa-check-double"></i> Notification Sent
                            </small>
                          </a>
                        </div>
                      ) : (
                        <>
                          {singleApprover === true ? (
                            <>
                              <div className="position-absolute notifyBtn">
                                <a className="btn btn-outline-success  m-0 disabled">
                                  <small>
                                    <i className="far fa-check"></i> Notification Sent
                                  </small>
                                </a>
                              </div>
                            </>
                          ) : (
                            <div className="position-absolute notifyBtn">
                              <a onClick={() => sendNotification("applicant", "approvingRepresentatives", setSingleApprover(true))} className="btn btn-primary text-white m-0">
                                <small>Submit Revision</small>
                              </a>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {roleName == "Approving Representative" && !paymentValue && allCommentsApplicant && allCommentsApplicant.length > 0 && resolvedValue === false && (
                    <>
                      {notification == "applicant" ? (
                        <div className="position-absolute notifyBtn">
                          <a className="btn btn-success  m-0 disabled">
                            <small>
                              <i className="fas fa-check-double"></i> Notification Sent
                            </small>
                          </a>
                        </div>
                      ) : (
                        <>
                          {singlehntb === true ? (
                            <div className="position-absolute notifyBtn">
                              <a className="btn btn-outline-success  m-0 disabled">
                                <small>
                                  <i className="far fa-check"></i> Notification Sent
                                </small>
                              </a>
                            </div>
                          ) : (
                            <div className="position-absolute notifyBtn">
                              <a onClick={() => sendNotification("approvingRepresentatives", "applicant", setSingleHntb(true))} className="btn btn-primary text-white m-0">
                                <small>Notify Applicant</small>
                              </a>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <div className="card-header  border-bottom">
                    <div className="d-flex justify-content-between align-items-center">
                      <h2 className="mb-0">Insurance Uploaded</h2>
                      <small className="text-muted">By Applicant</small>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="mb-3">
                      <div className="d-flex justify-content-end align-items-center mb-2">
                        {validated === false &&
                        !resolvedValue &&
                        ((roleName === "applicant" && allCommentsApplicant && allCommentsApplicant.length > 0) ||
                          roleName === "Approving Representative" ||
                          (roleName === "Engineering Representative" && allCommentsApplicant?.length > 0)) ? (
                          <button
                            className={` btn-sm 
                      btn  comment-btn
                       position-relative ${roleName !== "Engineering Representative" ? " btn-outline-dark text-dark" : "btn-outline-success text-success"}`}
                            data-bs-toggle="modal"
                            data-bs-target="#comment-modal2"
                          >
                            {roleName !== "Engineering Representative" &&
                              ((allCommentsApplicant && allCommentsApplicant.length == 0) || allCommentsApplicant === undefined ? "Start New Comment" : "Comments")}
                            {roleName === "Engineering Representative" && allCommentsApplicant && allCommentsApplicant.length > 0 ? "View" : null}
                          </button>
                        ) : null}
                        {validated === false &&
                        resolvedValue === true &&
                        (roleName === "Engineering Representative" || roleName === "Approving Representative" || roleName === "applicant") &&
                        allCommentsApplicant &&
                        allCommentsApplicant.length > 0 ? (
                          <button
                            className="btn-outline-success btn-sm 
                      btn text-success comment-btn
                       position-relative"
                            data-bs-toggle="modal"
                            data-bs-target="#comment-modal2"
                          >
                            <i className="far fa-check text-success"></i> Resolved
                          </button>
                        ) : null}
                      </div>
                      <CompileComments2
                        ID={ID}
                        qId={qId}
                        applicationID={ItemId}
                        amount={totalAmount} //license_fee
                        transID={transactionId}
                        fileNameOriginal1={fileNameOriginal1}
                        fileNameOriginal2={fileNameOriginal2}
                        fileNameOriginal3={fileNameOriginal3}
                        fileNameOriginal4={fileNameOriginal4}
                        fileNameUpdated1={fileNameUpdated1}
                        fileNameUpdated2={fileNameUpdated2}
                        fileNameUpdated3={fileNameUpdated3}
                        fileNameUpdated4={fileNameUpdated4}
                        handleComment={handleComment}
                        commentsResolve={commentsResolve}
                        resolveCommet={resolveCommet}
                        resolvedComment={resolvedComment}
                        resolveCommentId={resolveCommentId}
                        fileName3={fileName3}
                        fileName4={fileName4}
                        feevalue={riskFinanceFee}
                        setFile={setFile}
                        putComment={putComment}
                        resolvedValue={resolvedValue}
                        resolveCompileComment={resolveCompileComment}
                        UnresolveCompileComment={UnresolveCompileComment}
                      />
                      <div className="mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <label className="text-muted d-block form-label">Payment Instructions</label>
                        </div>
                        <div className="card bg-secondary border-0">
                          <div className="card-body p-3">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <i className="fal fa-file-pdf"></i>
                              </div>
                              <div className="flex-grow-1 ms-3 w-50">{paymentInstructionsfileName}</div>
                              <div className="flex-shrink-0">
                                <a href={API_URL + File + paymentInstructionsfileNameUpdated} target="_blank" className="btn btn-link">
                                  View
                                </a>
                                <a href={API_URL + File + paymentInstructionsfileNameUpdated} target="_blank" className="btn btn-link">
                                  Download
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {!showInputTag && (
                        <>
                          <div className="card bg-secondary border-0 mb-2">
                            <div className="card-body">
                              <label className="text-muted d-block form-label">North Carolina Railroad Co., 2809 Highwoods Blvd, Raleigh, NC 27604</label>
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <i className="fal fa-file-pdf"></i>
                                </div>
                                <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal1}</div>
                                <div className="flex-shrink-0">
                                  <a href={API_URL + File + fileNameUpdated1} target="_blank" className="btn btn-link">
                                    View
                                  </a>
                                  <a href={API_URL + File + fileNameUpdated1} target="_blank" download className="btn btn-link">
                                    Download
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card bg-secondary border-0 mb-2">
                            <div className="card-body ">
                              <label className="text-muted d-block form-label">Norfolk Southern Railway Co., Risk Management Dept, 650 West Peachtree Street NW, Box 46, Atlanta, GA 30308</label>
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <i className="fal fa-file-pdf"></i>
                                </div>
                                <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal2}</div>
                                <div className="flex-shrink-0">
                                  <a href={API_URL + File + fileNameUpdated2} target="_blank" className="btn btn-link">
                                    View
                                  </a>
                                  <a href={API_URL + File + fileNameUpdated2} target="_blank" download className="btn btn-link">
                                    Download
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr className="bg-black" style={{ backgroundColor: " rgba(0, 0, 0, 0.5)" }} />
                          <div className="card bg-secondary border-0 mb-3 p-4" id="optionCard1">
                            <div>
                              <div className="form-check mb-3 d-flex align-items-center">
                                <input className="form-check-input" type="checkbox" value="optionOne" id="optionOne" checked={!riskFinanceFee} disabled={true} />
                                <label className="form-check-label text-muted" htmlFor="optionOne" style={{ fontFamily: "Oswald,sans-serif" }}>
                                  Option 1
                                </label>
                              </div>
                              <div id="optn1">
                                <p>
                                  <small>Upload Proof of Railroad Protective Liability Insurance for NCRR and NSR as specified in the body of the agreement and Exhibit B, Article 9.</small>
                                </p>
                                {!riskFinanceFee && fileName3 && (
                                  <div className="card bg-secondary border-0 mb-2">
                                    <div className="card-body">
                                      <label className="text-muted d-block form-label">North Carolina Railroad Co., 2809 Highwoods Blvd, Raleigh, NC 27604</label>
                                      <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">
                                          <i className="fal fa-file-pdf"></i>
                                        </div>
                                        <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal3}</div>
                                        <div className="flex-shrink-0">
                                          <a href={API_URL + File + fileNameUpdated3} target="_blank" className="btn btn-link">
                                            View
                                          </a>
                                          <a href={API_URL + File + fileNameUpdated3} target="_blank" download className="btn btn-link">
                                            Download
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {!riskFinanceFee && fileName4 && (
                                  <div className="card bg-secondary border-0 mb-2">
                                    <div className="card-body">
                                      <label className="text-muted d-block form-label">
                                        Norfolk Southern Railway Co., Risk Management Dept, 650 West Peachtree Street NW, Box 46, Atlanta, GA 30308
                                      </label>
                                      <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">
                                          <i className="fal fa-file-pdf"></i>
                                        </div>
                                        <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal4}</div>
                                        <div className="flex-shrink-0">
                                          <a href={API_URL + File + fileNameUpdated4} target="_blank" className="btn btn-link">
                                            View
                                          </a>
                                          <a href={API_URL + File + fileNameUpdated4} target="_blank" download className="btn btn-link">
                                            Download
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="card bg-secondary border-0 mb-3 p-4" id="optionCard2">
                            <div>
                              <div className="form-check mb-3 d-flex align-items-center">
                                <input className="form-check-input" type="checkbox" value="" id="flexSwitchCheckChecked" disabled={true} checked={riskFinanceFee} />
                                <label className="form-check-label text-muted" htmlFor="flexSwitchCheckChecked" style={{ fontFamily: "Oswald,sans-serif" }}>
                                  Option 2
                                </label>
                              </div>
                              <div id="option2">
                                <div>
                                  <p id="optn2">
                                    <small>
                                      Pay ${REACT_APP_NS_risk_finance_fee_original} Risk Financing Fee to be added to NSR's Master Railroad Protective Liability Insurance coverage, as described in the
                                      attached special provision.
                                    </small>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {showInputTag && (
                        <>
                          <div className="card bg-secondary border-0 mb-3">
                            <div className="card-body">
                              <label className="text-muted d-block form-label">North Carolina Railroad Co., 2809 Highwoods Blvd, Raleigh, NC 27604</label>

                              <div className="input-group mb-2">
                                <input
                                  accept=".pdf"
                                  type="file"
                                  className="form-control"
                                  name="Certificates_of_Insurance_1"
                                  id="Certificates_of_Insurance_1"
                                  {...register("Certificates_of_Insurance_1", {
                                    required: complieData1 ? true : false,
                                    onChange: (e) => {
                                      const fileName = e.target.files[0].name;
                                      if (!checkFileName(fileName)) {
                                        setCharacterError((data) => ({
                                          ...data,
                                          Certificates_of_Insurance_1: true,
                                          Certificates_of_Insurance_1Name: fileName,
                                        }));
                                      } else {
                                        setCharacterError((data) => ({
                                          ...data,
                                          Certificates_of_Insurance_1: false,
                                          Certificates_of_Insurance_1Name: "",
                                        }));
                                      }
                                    },
                                  })}
                                  disabled={!complieData1}
                                />
                                <label className="input-group-text bg-secondary" htmlFor="Certificates_of_Insurance_1">
                                  Upload
                                </label>
                              </div>
                              {!complieData1 && fileNameOriginal1 ? (
                                <div className="pl-3 mt-2">
                                  {fileNameOriginal1}
                                  <button onClick={deleteItem} className="btn btn-link">
                                    <i style={{ color: "red" }} className="fa fa-trash" />
                                  </button>
                                </div>
                              ) : null}
                              {complieData1 && (
                                <span className="text-danger">
                                  <span>
                                    {errors.Certificates_of_Insurance_1 && errors.Certificates_of_Insurance_1.type === "required" && <span>This field is required</span>}
                                    {characterError.Certificates_of_Insurance_1 && <span className="text-sm">Remove special characters from file name, allowed special characters are - and _</span>}
                                  </span>
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="card bg-secondary border-0 mb-5">
                            <div className="card-body">
                              <label className="text-muted d-block form-label">Norfolk Southern Railway Co., Risk Management Dept, 650 West Peachtree Street NW, Box 46, Atlanta, GA 30308</label>
                              <div className="input-group mb-2">
                                <input
                                  accept=".pdf"
                                  type="file"
                                  className="form-control"
                                  name="Certificates_of_Insurance_2"
                                  id="Certificates_of_Insurance_2"
                                  {...register("Certificates_of_Insurance_2", {
                                    required: complieData2 ? true : false,
                                    onChange: (e) => {
                                      const fileName = e.target.files[0].name;
                                      if (!checkFileName(fileName)) {
                                        setCharacterError((data) => ({
                                          ...data,
                                          Certificates_of_Insurance_2: true,
                                          Certificates_of_Insurance_2Name: fileName,
                                        }));
                                      } else {
                                        setCharacterError((data) => ({
                                          ...data,
                                          Certificates_of_Insurance_2: false,
                                          Certificates_of_Insurance_2Name: "",
                                        }));
                                      }
                                    },
                                  })}
                                  disabled={!complieData2}
                                />
                                <label className="input-group-text bg-secondary" htmlFor="Certificates_of_Insurance_2">
                                  Upload
                                </label>
                              </div>
                              {!complieData2 && fileNameOriginal2 ? (
                                <div className="pl-3 mt-2">
                                  {fileNameOriginal2}
                                  <button onClick={deleteItem1} className="btn btn-link">
                                    <i style={{ color: "red" }} className="fa fa-trash" />
                                  </button>
                                </div>
                              ) : null}
                              {complieData2 && (
                                <span className="text-danger">
                                  <span>
                                    {errors.Certificates_of_Insurance_2 && errors.Certificates_of_Insurance_2.type === "required" && <span>This field is required</span>}
                                    {characterError.Certificates_of_Insurance_2 && <span className="text-sm">Remove special characters from file name, allowed special characters are - and _</span>}
                                  </span>
                                </span>
                              )}
                            </div>
                          </div>
                          <hr className="bg-black" style={{ backgroundColor: " rgba(0, 0, 0, 0.5)" }} />
                          <div className="card bg-secondary border-0 mb-3 p-4" id="optionCard1">
                            <div>
                              <div className="form-check mb-3 d-flex align-items-center">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value="optionOne"
                                  id="optionOne"
                                  onChange={() => {
                                    setReskFinanceFee(false);
                                    setComplieData4(true);
                                    setComplieData3(true);
                                  }}
                                  checked={!riskFinanceFee}
                                />
                                <label className="form-check-label text-muted" htmlFor="optionOne" style={{ fontFamily: "Oswald,sans-serif" }}>
                                  Option 1
                                </label>
                              </div>
                              <div id="optn1">
                                <p>
                                  <small>Upload Proof of Railroad Protective Liability Insurance for NCRR and NSR as specified in the body of the agreement and Exhibit B, Article 9.</small>
                                </p>
                                <label className="text-muted d-block form-label">North Carolina Railroad Co., 2809 Highwoods Blvd, Raleigh, NC 27604</label>

                                {complieData3 ? (
                                  <div className="input-group mb-2">
                                    <input
                                      accept=".pdf"
                                      type="file"
                                      className="form-control"
                                      name="Certificates_of_Insurance_3"
                                      id="Certificates_of_Insurance_3"
                                      disabled={riskFinanceFee}
                                      {...register("Certificates_of_Insurance_3", {
                                        required: true,
                                        onChange: (e) => {
                                          const fileName = e.target.files[0].name;
                                          if (!checkFileName(fileName)) {
                                            setCharacterError((data) => ({
                                              ...data,
                                              Certificates_of_Insurance_3: true,
                                              Certificates_of_Insurance_3Name: fileName,
                                            }));
                                          } else {
                                            setCharacterError((data) => ({
                                              ...data,
                                              Certificates_of_Insurance_3: false,
                                              Certificates_of_Insurance_3Name: "",
                                            }));
                                            setComplieData3(true);
                                          }
                                        },
                                      })}
                                    />
                                    <label className="input-group-text bg-secondary" htmlFor="Certificates_of_Insurance_3">
                                      Upload
                                    </label>
                                  </div>
                                ) : (
                                  <div className="input-group mb-2">
                                    <input
                                      accept=".pdf"
                                      type="file"
                                      className="form-control"
                                      name="Certificates_of_Insurance_3"
                                      id="Certificates_of_Insurance_3"
                                      disabled={riskFinanceFee}
                                      // {...register(
                                      //   "Certificates_of_Insurance_3",
                                      //   {
                                      //     // required: true,
                                      //     onChange: (e) => {
                                      //       const fileName =
                                      //         e.target.files[0].name;
                                      //       if (!checkFileName(fileName)) {
                                      //         setCharacterError((data) => ({
                                      //           ...data,
                                      //           Certificates_of_Insurance_3: true,
                                      //           Certificates_of_Insurance_3Name:
                                      //             fileName,
                                      //         }));
                                      //       } else {
                                      //         setCharacterError((data) => ({
                                      //           ...data,
                                      //           Certificates_of_Insurance_3: false,
                                      //           Certificates_of_Insurance_3Name:
                                      //             "",
                                      //         }));
                                      //         setComplieData3(true);
                                      //       }
                                      //     },
                                      //   }
                                      // )}
                                      // disabled={
                                      //   !complieData2
                                      // }
                                    />
                                    <label className="input-group-text bg-secondary" htmlFor="Certificates_of_Insurance_3">
                                      Upload
                                    </label>
                                  </div>
                                )}

                                {!complieData3 && fileNameOriginal3 ? (
                                  <div className="pl-3 mt-2">
                                    {fileNameOriginal3}
                                    <button onClick={deleteItem3} className="btn btn-link">
                                      <i style={{ color: "red" }} className="fa fa-trash" />
                                    </button>
                                  </div>
                                ) : null}
                                {complieData3 && (
                                  <span className="text-danger">
                                    <span>
                                      {errors.Certificates_of_Insurance_3 && errors.Certificates_of_Insurance_3.type === "required" && <span>This field is required</span>}
                                      {characterError.Certificates_of_Insurance_3 && <span className="text-sm">Remove special characters from file name, allowed special characters are - and _</span>}
                                    </span>
                                  </span>
                                )}

                                <label className="text-muted d-block form-label">Norfolk Southern Railway Co., Risk Management Dept, 650 West Peachtree Street NW, Box 46, Atlanta, GA 30308</label>

                                <div className="input-group mb-2">
                                  {complieData4 ? (
                                    <input
                                      type="file"
                                      accept=".pdf"
                                      className="form-control"
                                      name="Certificates_of_Insurance_4"
                                      id="Certificates_of_Insurance_4"
                                      disabled={riskFinanceFee}
                                      {...register("Certificates_of_Insurance_4", {
                                        required: true,
                                        onChange: (e) => {
                                          const fileName = e.target.files[0].name;
                                          if (!checkFileName(fileName)) {
                                            setCharacterError((data) => ({
                                              ...data,
                                              Certificates_of_Insurance_4: true,
                                              Certificates_of_Insurance_4Name: fileName,
                                            }));
                                          } else {
                                            setCharacterError((data) => ({
                                              ...data,
                                              Certificates_of_Insurance_4: false,
                                              Certificates_of_Insurance_4Name: "",
                                            }));
                                            setComplieData4(true);
                                          }
                                        },
                                      })}
                                      // disabled={
                                      //   !complieData2
                                      // }
                                    />
                                  ) : (
                                    <input
                                      type="file"
                                      accept=".pdf"
                                      className="form-control"
                                      name="Certificates_of_Insurance_4"
                                      id="Certificates_of_Insurance_4"
                                      disabled={riskFinanceFee}
                                      // {...register(
                                      //   "Certificates_of_Insurance_4",
                                      //   {
                                      //     // required: true,
                                      //     onChange: (e) => {
                                      //       const fileName =
                                      //         e.target.files[0].name;
                                      //       if (!checkFileName(fileName)) {
                                      //         setCharacterError((data) => ({
                                      //           ...data,
                                      //           Certificates_of_Insurance_4: true,
                                      //           Certificates_of_Insurance_4Name:
                                      //             fileName,
                                      //         }));
                                      //       } else {
                                      //         setCharacterError((data) => ({
                                      //           ...data,
                                      //           Certificates_of_Insurance_4: false,
                                      //           Certificates_of_Insurance_4Name:
                                      //             "",
                                      //         }));
                                      //         setComplieData4(true);
                                      //       }
                                      //     },
                                      //   }
                                      // )}
                                      // disabled={
                                      //   !complieData2
                                      // }
                                    />
                                  )}

                                  <label className="input-group-text bg-secondary" htmlFor="Certificates_of_Insurance_4">
                                    Upload
                                  </label>
                                </div>
                                {!complieData4 && fileNameOriginal4 ? (
                                  <div className="pl-3 mt-2">
                                    {fileNameOriginal4}
                                    <button onClick={deleteItem4} className="btn btn-link">
                                      <i style={{ color: "red" }} className="fa fa-trash" />
                                    </button>
                                  </div>
                                ) : null}
                                {complieData4 && (
                                  <span className="text-danger">
                                    <span>
                                      {errors.Certificates_of_Insurance_4 && errors.Certificates_of_Insurance_4.type === "required" && <span>This field is required</span>}
                                      {characterError.Certificates_of_Insurance_4 && <span className="text-sm">Remove special characters from file name, allowed special characters are - and _</span>}
                                    </span>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="card bg-secondary border-0 mb-3 p-4" id="optionCard2">
                            <div>
                              <div className="form-check mb-3 d-flex align-items-center">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexSwitchCheckChecked"
                                  onChange={() => {
                                    setReskFinanceFee(true);
                                    setComplieData3(false);
                                    setComplieData4(false);
                                  }}
                                  checked={riskFinanceFee}
                                />
                                <label className="form-check-label text-muted" htmlFor="flexSwitchCheckChecked">
                                  Option 2
                                </label>
                              </div>
                              <div id="option2">
                                <div>
                                  <p id="optn2">
                                    <small>
                                      Pay ${REACT_APP_NS_risk_finance_fee_original} Risk Financing Fee to be added to NSR's Master Railroad Protective Liability Insurance coverage, as described in the
                                      attached special provision.
                                    </small>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    {roleName === "applicant" && !showInputTag && !paymentValue && resolvedValue !== true && (
                      <button
                        className="btn btn-link"
                        style={{
                          textDecoration: "underline",
                          pointerEvents: "fill",
                        }}
                        onClick={showInput}
                      >
                        <i className="far fa-pen"></i> Edit
                      </button>
                    )}
                    {roleName === "applicant" && showInputTag && (
                      <>
                        <button
                          className="btn btn-link"
                          style={{
                            textDecoration: "underline",
                            pointerEvents: "fill",
                          }}
                          // onClick={onEditFormSubmit}
                        >
                          <i className="far fa-check"></i> Save
                        </button>
                        <a
                          className="btn btn-link text-warning"
                          style={{
                            textDecoration: "underline",
                            pointerEvents: "fill",
                          }}
                          href={REACT_APP_URL + "/compiledAgreement/" + ItemId}
                        >
                          <i className="far fa-ban"></i> Cancel
                        </a>
                      </>
                    )}
                    {roleName === "Approving Representative" && (
                      <div className="row">
                        <div className="col-lg-12 pt-3 p-0 d-flex flex-row-reverse">
                          <div className="form-check p-0">
                            {!showAll && (
                              <div className="btn btn-link text-primary text-underline" onClick={() => setShowAll(true)}>
                                Hide
                              </div>
                            )}
                            {showAll && (
                              <div className="btn btn-link text-primary text-underline" onClick={() => setShowAll(false)}>
                                Show All Default Fields
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {roleName === "Approving Representative" &&
                      arrayOfnesuite.map((data) => {
                        const { recordName, hide = false } = data;

                        if (hide) return null;

                        return (
                          <>
                            <h3 className="pt-3">{data.header} </h3>
                            <div className="row ">
                              {data.rows.map((datas, ind) =>
                                datas.columns.map((fiel, ind) => {
                                  let value = netsuiteField?.[recordName]?.[fiel.keyName] || "";
                                  return (
                                    <>
                                      {fiel.validateHide !== "true" && (fiel.defaultValidateShow === true || showAll === false) && (
                                        <div className={fiel.col ? fiel.col : "col-md-6"}>
                                          <FieldAll data={fiel} handleNetSuiteChange={handleNetSuiteChange} recordName={recordName} col={datas} value={value} />
                                        </div>
                                      )}
                                    </>
                                  );
                                }),
                              )}
                            </div>
                          </>
                        );
                      })}
                    {roleName === "Approving Representative" && !checkCommentsAndResolved && (
                      <p className="text-center text-warning mb-0">Upload Insurance button is disabled until the comment is resolved</p>
                    )}
                  </div>
                  {allCommentsApplicant && allCommentsApplicant.length > 0 && roleName === "Approving Representative" && resolvedValue === false && (
                    <div
                      className="card-footer disabled justify-content-center"
                      style={{
                        backgroundColor: !checkCommentsAndResolved ? "rgb(233 237 241)" : "#5E74E4",
                        color: !checkCommentsAndResolved ? "black" : "white",
                      }}
                      disabled={roleName !== "applicant" || !checkCommentsAndResolved}
                    >
                      {"Validate"}
                      <i className="fal fa-chevron-right ms-2"></i>
                    </div>
                  )}

                  {roleName !== "Approving Representative" && validated === false && (
                    <div className="card-footer justify-content-center" style={{ backgroundColor: "rgb(233 237 241)" }}>
                      {"Awaiting For Approval"}
                      <i className="fal fa-chevron-right ms-2"></i>
                    </div>
                  )}
                  {roleName !== "Approving Representative" && validated === true && (
                    <div className="card-footer py-0 justify-content-center bg-success">
                      <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                        <span className="d-flex justify-content-between align-items-center">
                          <span>
                            <i className="fal fa-check"></i> {"Uploaded"}{" "}
                          </span>
                          <span>By Applicant's on {date1 && <small> {date1 ? date1.slice(0, 15) : ""}</small>}</span>
                        </span>
                      </a>
                    </div>
                  )}
                  {roleName === "Approving Representative" && checkCommentsAndResolved && checkCommentsAndResolved2 && validated === false && (
                    <div
                      className={`card-footer justify-content-center ${!checkCommentsAndResolved ? "" : "bg-primary"}`}
                      style={{
                        border: "none",
                        color: !checkCommentsAndResolved ? "black" : "white",
                        backgroundColor: !checkCommentsAndResolved ? "rgb(233 237 241)" : "#5E74E4",
                        cursor: ` ${!checkCommentsAndResolved ? " " : "pointer"} `,
                      }}
                    >
                      <button
                        style={{
                          border: "none",
                          color: !checkCommentsAndResolved ? "black" : "white",
                          backgroundColor: !checkCommentsAndResolved ? "rgb(233 237 241)" : "#5E74E4",
                          cursor: ` ${!checkCommentsAndResolved ? " " : "pointer"} `,
                        }}
                        className={`d-block ${!checkCommentsAndResolved ? "disabled" : ""}`}
                        onClick={() => validateFee()}
                        // disabled={resolvedValues === true}
                      >
                        Validate
                        <i className="fal fa-chevron-right ms-2"></i>
                      </button>
                    </div>
                  )}
                  {roleName === "Approving Representative" && validated === true && (
                    <div className="card-footer py-0 justify-content-center bg-success">
                      <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                        <span className="d-flex justify-content-between align-items-center">
                          <span>
                            <i className="fal fa-check"></i> {"Validated"}
                          </span>
                          <span>By NCRR on {date1 && <small> {date1 ? date1.slice(0, 15) : ""}</small>}</span>
                        </span>
                      </a>
                    </div>
                  )}
                </form>
              </div>

              {validated === true && <CompiledPayment />}

              {(event2validatedOn || oldApplication) && (
                <div className="card mb-4 position-relative comment-card">
                  <div className="card-header  border-bottom">
                    <div className="d-flex justify-content-between align-items-center">
                      <h2 className="mb-0">Sign Agreement</h2>
                      <small className="text-muted">By Applicant</small>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="mb-3">
                      <div className="mb-2 mt-2">
                        {SignerEmail === contact?.email ? (
                          <label className="text-muted d-block form-label d-block">You are authorized to sign the Agreement.</label>
                        ) : (
                          <label className="text-muted d-block form-label d-block">{CurrentStatus === "Complete" ? "Agreement Signed" : "Who is authorized to sign the final agreement?"}</label>
                        )}
                        {SignerName && (
                          <>
                            <div className={`${SignerEmail === contact?.email || CurrentStatus === "Complete" ? "alert-success" : "alert-danger"} alert fs-5 mt-3 mb-0 text-center`}>
                              {CurrentStatus === "Complete" ? `${SignerName} digitally Signed this Agreement` : `Waiting for ${compileValue ? SignerName : ""} to digitally sign this Agreement.`}
                            </div>
                            {["applicant", "Engineering Representative"].find((each) => each === roleName) && CurrentStatus === "waiting" && (
                              <div className="d-flex justify-content-evenly p-3 align-items-center">
                                <div
                                  className="text-muted"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    sendEmailNotification(
                                      {
                                        name: SignerName,
                                        email: SignerEmail,
                                      },
                                      true,
                                    )
                                  }
                                >
                                  <u>Resend Email</u>
                                </div>
                                <div className="input-group w-75" role="group">
                                  <SelectForSignature assignSignee={sendEmailNotification} label="Reselect for Signature" />
                                </div>
                              </div>
                            )}
                          </>
                        )}
                        <div className="d-flex align-items-center mb-2">
                          {SignerName ? (
                            <div className="d-none"> </div>
                          ) : (
                            <div className="form-check">
                              <input
                                className="form-check-input SignByMe"
                                onClick={() => setMe(true)}
                                type="radio"
                                checked={me}
                                name="flexRadioDefault"
                                disabled={roleName !== "applicant"}
                                id="Signedyes"
                              />
                              <label className="form-check-label" htmlFor="Signedyes">
                                Me
                              </label>
                            </div>
                          )}
                          {SignerName ? (
                            <> </>
                          ) : (
                            <div className="form-check ms-4">
                              <input
                                className="form-check-input SignByOthers"
                                onClick={() => setMe(false)}
                                checked={!me}
                                disabled={roleName !== "applicant"}
                                type="radio"
                                name="flexRadioDefault"
                                id="signedno"
                              />
                              <label className="form-check-label" htmlFor="signedno">
                                Others
                              </label>
                            </div>
                          )}
                        </div>
                      </div>
                      {!me && <SelectForSignature assignSignee={sendEmailNotification} />}
                    </div>
                  </div>
                  {CurrentStatus === "Complete" ? (
                    <div className="card-footer py-0 justify-content-center bg-success">
                      <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                        <span className="d-flex justify-content-between align-items-center">
                          <span>
                            <i className="fal fa-check"></i> Signed
                          </span>
                          <span>
                            By {SignerName} on <small> {SignedDate ? SignedDate : ""}</small>
                          </span>
                        </span>
                      </a>
                    </div>
                  ) : (SignerEmail === contact?.email || !SignerEmail) && me ? (
                    <div className={`${roleName === "applicant" ? "" : "opacity-75"} card-footer justify-content-center bg-primary`} id="footerSign">
                      <div id="signDocu">
                        <a style={roleName === "applicant" ? {} : { pointerEvents: "none" }} onClick={signatureFunction} className="d-block text-white">
                          Sign
                          <i className="fal fa-chevron-right ms-2"></i>
                        </a>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )}
              {CurrentStatus === "Complete" && (
                <div className="card mb-4 border-primary shadow ">
                  <div className="card-header  border-bottom">
                    <div className="d-flex justify-content-between align-items-center">
                      <h2 className="mb-0">Validate Fee, Insurance and Agreement </h2>
                      <small className="text-muted">For NCRR</small>
                    </div>
                    {roleName == "Approving Representative" && !downloadSignedDocuments1 ? (
                      <div className="mt-3">
                        <a
                          className={roleName == "Approving Representative" ? (!downloadSignedDocuments1 ? "btn btn-sm btn-primary" : " ") : ""}
                          disabled={roleName !== "Approving Representative"}
                          href={`${API_URL}/eg001?applicationID=${ItemId}`}
                        >
                          {!downloadSignedDocuments1 ? "Update License Agreement for validation " : " "}
                        </a>
                      </div>
                    ) : (
                      " "
                    )}
                  </div>
                  {roleName !== "Approving Representative" ? (
                    <div className="card-footer justify-content-center " style={{ backgroundColor: "rgb(233 237 241)" }}>
                      Awaiting for NCRR
                      <i className="fal fa-chevron-right ms-2"></i>
                    </div>
                  ) : (
                    <>
                      <div className="card-footer justify-content-center bg-primary">
                        <button
                          style={{ border: "none", backgroundColor: "#5E74E4" }}
                          className="d-block text-white"
                          onClick={() => {
                            props.validateFee();
                          }}
                          disabled={roleName !== "Approving Representative" || validated === false}
                        >
                          {roleName !== "Approving Representative" ? "Awaiting for NCRR" : "Validate"} <i className="fal fa-chevron-right ms-2"></i>
                        </button>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <AddContactModal assignSignee={sendEmailNotification} />
    </div>
  );
}

const SelectForSignature = ({ assignSignee, label = "Select contact for Signature" }) => {
  const application = useApplication();
  const user = useUser();
  const [facilityContacts, setFacilityContacts] = useState([]);
  const [consultantContacts, setConsultantContacts] = useState([]);
  const [applicationContacts, setApplicationContacts] = useState([]);

  const [selectedContact, setSelectedContact] = useState(null);

  const getFacilityContacts = async (companyID, applicantDetails) => {
    const res = await getContactsByCompanyId(companyID);
    if (res.data.status) {
      const contacts = res.data.data;
      const { primaryContact, billingContact, consultantContact, signatoryContact, engineeringContact } = applicantDetails;
      const filteredContacts = contacts.filter((each) => {
        if (each.id === primaryContact?.id) {
          return false;
        }
        if (each.id === billingContact?.id) {
          return false;
        }
        if (each.id === consultantContact?.id) {
          return false;
        }
        if (each.id === engineeringContact?.id) {
          return false;
        }
        if (each.id === signatoryContact?.id) {
          return false;
        }
        return true;
      });
      setFacilityContacts(filteredContacts);
    }
  };

  const getConsultantContacts = async (companyID, applicantDetails) => {
    const res = await getContactsByCompanyId(companyID);
    if (res.data.status) {
      const contacts = res.data.data;
      const { primaryContact, billingContact, consultantContact, signatoryContact, engineeringContact } = applicantDetails;
      const filteredContacts = contacts.filter((each) => {
        if (each.id === primaryContact?.id) {
          return false;
        }
        if (each.id === billingContact?.id) {
          return false;
        }
        if (each.id === consultantContact?.id) {
          return false;
        }
        if (each.id === engineeringContact?.id) {
          return false;
        }
        if (each.id === signatoryContact?.id) {
          return false;
        }
        return true;
      });
      setConsultantContacts(filteredContacts);
    }
  };

  const getApplicationContacts = (applicantDetails) => {
    const { primaryContact, billingContact, consultantContact, signatoryContact, engineeringContact } = applicantDetails;
    const applicationContacts = [];

    if (billingContact) {
      if (!applicationContacts.some((each) => each.id === billingContact.id)) {
        applicationContacts.push(billingContact);
      }
    }
    if (primaryContact) {
      if (!applicationContacts.some((each) => each.id === primaryContact.id)) {
        applicationContacts.push(primaryContact);
      }
    }
    if (consultantContact) {
      if (!applicationContacts.some((each) => each.id === consultantContact.id)) {
        applicationContacts.push(consultantContact);
      }
    }
    if (signatoryContact) {
      if (!applicationContacts.some((each) => each.id === signatoryContact.id)) {
        applicationContacts.push(signatoryContact);
      }
    }
    if (engineeringContact) {
      if (!applicationContacts.some((each) => each.id === engineeringContact.id)) {
        applicationContacts.push(engineeringContact);
      }
    }
    setApplicationContacts(applicationContacts);
  };

  useEffect(() => {
    if (application) {
      const { consultantId, facilityId, applicantDetails } = application;
      if (consultantId) {
        getConsultantContacts(consultantId, applicantDetails);
      }
      if (facilityId) {
        getFacilityContacts(facilityId, applicantDetails);
      }
      if (applicantDetails) {
        getApplicationContacts(applicantDetails);
      }
    }
  }, [application]);

  if (!application || !user) {
    return <DashBoardLoading />;
  }

  const handleSelectChange = (id) => {
    let contact = null;

    if (id === "NEW") {
      setSelectedContact(id);
      return;
    }

    if (!contact) {
      contact = applicationContacts.find((each) => each.id === id);
    }

    if (!contact) {
      contact = facilityContacts.find((each) => each.id === id);
    }
    if (!contact) {
      contact = consultantContacts.find((each) => each.id === id);
    }

    setSelectedContact(contact);
  };

  return (
    <div className="card bg-secondary border-0">
      <div id="div1" className="card-body p-3">
        <div className="input-group" role="group">
          <select className="form-select" id="search-select" onChange={(e) => handleSelectChange(e.target.value)} aria-label="Default select example">
            <option value="" selected disabled>
              {label}
            </option>
            <optgroup
              label="Application Contacts"
              style={{
                color: "gray",
              }}
            >
              {applicationContacts.map((each) => {
                return <option key={each?.id} value={each?.id}>{`${each.name} (${each?.email})`}</option>;
              })}
            </optgroup>
            {facilityContacts.length > 0 && (
              <optgroup
                label="Facility Contacts"
                style={{
                  color: "gray",
                }}
              >
                {facilityContacts.map((each) => {
                  return <option key={each?.id} value={each?.id}>{`${each.name} (${each?.email})`}</option>;
                })}
              </optgroup>
            )}
            {consultantContacts.length > 0 && (
              <optgroup
                label="Engineering Contacts"
                style={{
                  color: "gray",
                }}
              >
                {consultantContacts.map((each) => {
                  return <option key={each?.id} value={each?.id}>{`${each.name} (${each?.email})`}</option>;
                })}
              </optgroup>
            )}
            <optgroup
              label="New"
              style={{
                color: "gray",
              }}
            >
              <option value={"NEW"}>Add New Contact</option>
            </optgroup>
          </select>
          {selectedContact && selectedContact !== "NEW" && (
            <div id="hidden_div" className="dropdown-content-bond">
              <button
                type="button"
                className="btn btn-success"
                style={{
                  borderRadius: "0 6px 6px 0",
                  height: "100%",
                  paddingBottom: "0.7rem !important",
                  paddingTop: " 0.75rem !important",
                }}
                onClick={() => assignSignee(selectedContact)}
              >
                Send
              </button>
            </div>
          )}
          {selectedContact === "NEW" && (
            <div id="hidden_div2" className="dropdown-content-bond">
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop24"
                className="btn btn-success"
                style={{
                  borderRadius: "0 6px 6px 0",
                  height: "100%",
                  paddingBottom: "0.7rem !important",
                  paddingTop: " 0.75rem !important",
                }}
              >
                Add
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const AddContactModal = ({ assignSignee }) => {
  const application = useApplication();
  const user = useUser();
  const [contact, setContact] = useState({
    name: "",
    email: "",
    role: "",
  });
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (user) {
      const { company } = user;
      if (company) {
        const { companyType } = company;
        if (companyType === "FACILITY") {
          const roles = [
            {
              name: "role",
              value: "Primary Representative",
              label: "Primary Representative",
            },
            {
              name: "role",
              value: "Billing Representative",
              label: "Billing Representative",
            },
          ];
          setRoles(roles);
        }

        if (companyType === "CONSULTANT") {
          const roles = [
            {
              name: "role",
              value: "Primary Representative",
              label: "Primary Representative",
            },
            {
              name: "role",
              value: "Billing Representative",
              label: "Billing Representative",
            },
            {
              name: "role",
              value: "Engineering Representative",
              label: "Engineering Representative",
            },
          ];
          setRoles(roles);
        }
      }
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!contact.name || !contact.email || !contact.role) {
      return;
    }

    const { facilityId, consultantId } = application;
    const { name, email, role } = contact;

    const data = {
      name,
      jobTitle: "",
      email,
      phone: "",
      role: [role],
      default: false,
      address: {
        address: "",
        city: "",
        state: "",
        zip: "",
        poBox: "",
        country: "United States",
      },
    };

    if (role === "Primary Representative" || role === "Billing Representative") {
      data["companyId"] = facilityId;
    }

    if (role === "Engineering Representative") {
      data["companyId"] = consultantId;
    }
    await createContact(data);
    assignSignee({ name, email });
  };

  const handleChange = (e) => {
    if (e?.name) {
      setContact({ ...contact, [e.name]: e.value });
      return;
    }
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  return (
    <div className="modal fade" id="staticBackdrop24" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <label htmlFor="">Add Contact</label>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input required={true} type="text" name="name" onChange={handleChange} className="form-control mb-3" id="name" placeholder="Enter Name" />
              <label htmlFor="email" className="form-label">
                Email address
              </label>
              <input required={true} type="email" name="email" onChange={handleChange} className="form-control" id="email" placeholder="Enter Email Address" />
              <div className="mt-3">
                <label className="form-label d-flex justify-content-between">Role</label>
                <Select placeholder="Select Roles" required={true} onChange={handleChange} options={roles} />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
              <button type="submit" className="btn btn-success">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
