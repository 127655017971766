/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getFormData, getLicenseStatus, putNextStatus, sendNotify } from "../../api/currentApplications";
import UploadDraftLoading from "../loaders/UploadDraftLoading";
import { API_URL, File, REACT_APP_URL } from "../../api";
import { useLocation } from "react-router-dom";
import { Netsuite } from "../../schema/NetSuiteSchema";
import { Tooltip } from "bootstrap";
import checkFileName from "../../utils/checkSpecialCharacter";
import { getNetsuiteInitialValue, netSuiteUpsertRecords, search, upsertSupplementRecord } from "../../api/Netsuite";
import FieldAll from "../ui/FieldAll";
import { Checkbox } from "../ui/CheckBox";
import { SearchBar } from "../ui/SearchBar";
import { useUser } from "../../hooks/useUser";
import { useApplication, useApplicationRefetch } from "../../hooks/useApplication";
import { createContact, getContactsByCompanyId } from "../../api/crm";
import Select from "react-select";
import DashBoardLoading from "../loaders/DashBoardLoading";
import { ApplicantDetailsModel } from "../models/ApplicantDetailsModel";

export default function UploadDraftAgreement(props) {
  const { roleName = "", id: contactRoleId, contact = {} } = useUser() || {};
  const application = useApplication();
  const applicationRefetch = useApplicationRefetch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      supplement: false,
    },
  });

  const [netsuiteField, setNetsuiteValue] = React.useState({
    UNIT: {},
    OPPORTUNITY: {},
    AGREEMENT: {},
    UTILITY: {},
  });
  const [netsuiteAgreement, setNetsuiteAgreement] = React.useState([]);
  const [selectedAgreement, setSelectedAgreement] = React.useState("");
  let { ItemId, stage } = props;
  const onSubmit = async (data) => {
    if (!characterError.agreement && !characterError.supporting_doc) {
      const { UNIT, UTILITY, AGREEMENT, OPPORTUNITY } = netsuiteField;
      await netSuiteUpsertRecords(ItemId, "OPPORTUNITY", OPPORTUNITY);
      await netSuiteUpsertRecords(ItemId, "AGREEMENT", AGREEMENT);
      await netSuiteUpsertRecords(ItemId, "UTILITY", UTILITY);
      await netSuiteUpsertRecords(ItemId, "UNIT", UNIT);
      if (supplementAgreement) {
        await upsertSupplementRecord(ItemId, {
          supplement: true,
          agreement: selectedAgreement,
        });
      }
      props.uploadDraftAggrement(data);
    }
  };

  const [loading, setLoading] = React.useState(true);
  const [applicationsignedDocumentsEnvelopeId, setapplicationsignedDocumentsEnvelopeId] = React.useState("");
  const [compileValues, setCompileValues] = React.useState({});
  const [compileValue, setCompileValue] = React.useState({});
  const [formNumber, setFormNumber] = React.useState();
  const [showAll, setShowAll] = React.useState([]);
  const [arrayOfnesuite, setArrayOfnesuite] = React.useState([]);
  const [supplementAgreement, setSupplementAgreement] = React.useState(false);
  const [characterError, setCharacterError] = React.useState({
    agreement: false,
    supporting_doc: false,
  });

  const [records, setRecords] = React.useState([]);
  const SignedDate = compileValue ? (compileValue.signedAt ? new Date(compileValue.signedAt).toString().slice(0, 15) : "") : "";
  const validateSignedDate = compileValue ? (compileValue.validatedat ? new Date(compileValue.validatedat).toString().slice(0, 15) : "") : "";
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl);
  });
  React.useEffect(async () => {
    setLoading(true);
    let response = await getLicenseStatus(ItemId);
    setCompileValues(response.data.data);
    setLoading(false);
  }, []);
  let { downloadSignedDocuments0 } = compileValues || {};
  React.useEffect(async () => {
    setLoading(true);

    getFormData(ItemId).then(({ data }) => {
      if (data && data.signedDocumentsEnvelopeId) {
        setapplicationsignedDocumentsEnvelopeId(data.signedDocumentsEnvelopeId);
      }
    });
    let formData = await getFormData(ItemId);
    if (formData.data.applicationData && formData.data.applicationData) {
      localStorage.setItem(ItemId, JSON.stringify(formData.data.applicationData));
    }
  }, []);
  React.useEffect(() => {
    const fetchApplicationData = async () => {
      const { formNumber } = application;
      setFormNumber(formNumber);
    };
    fetchApplicationData();
  }, []);

  useEffect(async () => {
    setLoading(true);
    const object = {
      applicationID: ItemId,
      signerEmail: ["Test"],
    };
    let response = await sendNotify(object);
    setCompileValue(response.data.responseData);
  }, []);

  let currentEmail = contact.email;
  let { name: SignerName, email: SignerEmail, status: CurrentStatus, validatedby } = compileValue || {};
  const [me, setMe] = useState(true);

  useEffect(() => {
    Netsuite.sections.forEach((section) => {
      section.questions.forEach((val) => {
        if (val.draftAgreement === "true") {
          setArrayOfnesuite((initialValue) => [...initialValue, val]);
        }
      });
    });
  }, [Netsuite]);

  useEffect(() => {
    const array = arrayOfnesuite.map((section) => {
      return section?.recordName;
    });
    setRecords(array);
  }, [arrayOfnesuite]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const promises = records.map(async (record) => {
          const res = await getNetsuiteInitialValue(ItemId, record);
          const response = res?.data?.data;
          return { [record]: response };
        });
        const results = await Promise.all(promises);

        const mergedResults = results.reduce((acc, result) => ({ ...acc, ...result }), {});

        setNetsuiteValue((initialValue) => ({
          ...initialValue,
          ...mergedResults,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error as needed
      }
    };
    const { currentStep = 0 } = stage;
    if (currentStep >= 9) {
      fetchData();
    }
  }, [records, ItemId, getNetsuiteInitialValue]);

  let { billingContact, primaryContact, consultantContact, facilityCompany } = application?.applicantDetails || {};
  let { billingAddress, name: company_name, billingPeriod: billing_period, applicantshipType: Applicantship_type, stateOfFormation: state_of_formation } = facilityCompany || {};
  let { address: company_street, city: company_city, state: company_state, zip: company_zip } = billingAddress || {};

  let { address, name: billing_name, jobTitle: billing_title, email: billing_email, phone: billing_number } = billingContact || {};
  let { address: consultantContactAddress, name: consultant_person_name, jobTitle: consultant_title, phone: consultant_number, email: consultant_email } = consultantContact || {};
  let {
    city: consultant_city,
    state: consultant_state,
    address: consultant_street_address,

    zip: consultant_zip,
  } = consultantContactAddress || {};

  let {
    city: billing_city,
    state: billing_state,
    address: billing_street_address,
    // phone: billing_number,
    zip: billing_zip,
  } = address || {};
  let { address: primaryContactAddress, name: representative_name, jobTitle: representative_title, email: representative_email, phone: representative_number } = primaryContact || {};
  let { city: representative_city, state: representative_state, address: representative_street_address, zip: representative_zip } = primaryContactAddress || {};

  const sendEmailNotification = async ({ name, email }, resend = false) => {
    setLoading(true);
    let linkUrl = REACT_APP_URL + `/draftAgreement/${ItemId}`;
    let data = {
      applicationID: ItemId,
      signerName: name,
      signerEmail: [email],
      userId: contactRoleId,
      LinkUrl: linkUrl,
      status: "waiting",
      resend: resend,
    };
    await sendNotify(data);
    await putNextStatus(ItemId, 1);
    applicationRefetch();
  };

  const signatureFunction = async () => {
    setLoading(true);
    let applicationID = ItemId ? ItemId : "";

    let pdfFileS = applicationID + ".pdf";
    if (SignerName && SignerEmail) {
      var signerNames = SignerName;
      var signerEmails = SignerEmail ? SignerEmail : "";
    } else {
      var signerNames = representative_name;
      var signerEmails = representative_email ? representative_email : "";
    }
    const { name, email } = contact;
    let pdfFile = applicationID + ".pdf";
    if (me) {
      let data = {
        applicationID: ItemId,
        signerName: name,
        signerEmail: [email],
        signBy: "me",
      };
      await sendNotify(data);
    }
    await putNextStatus(ItemId, 1);
    let dsReturnUrl = `${REACT_APP_URL}/applicationSignatureWaiting/${ItemId}`;
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
    window.location = `${REACT_APP_API_URL}/eg001?signerName=${name}&signerEmail=${email}&pdfFile=${pdfFile}&dsReturnUrl=${dsReturnUrl}`;
    return;
  };

  const validateSign = async () => {
    setLoading(true);
    let data = {
      applicationID: ItemId,
      signerEmail: ["Test"],
      LinkUrl: process.env.REACT_APP_URL + window.location.pathname,
      validatedby: "HNTB",
    };
    await sendNotify(data);
    await putNextStatus(ItemId, 1);
    applicationRefetch();
  };

  const handleNetSuiteChange = (data, event, recordName, column) => {
    let item = 0;
    if (column?.itemId) {
      item = column.itemId;
    }
    const { keyName = "" } = data;
    const { value } = event.target;
    const acceptedType1 = ["text", "date", "number", "dropdown"];
    const acceptedType2 = ["textarea"];
    const type = data.type;
    if (acceptedType1.includes(type) && item === 0) {
      setNetsuiteValue((initialValue) => ({
        ...initialValue,
        [recordName]: { ...initialValue[recordName], [keyName]: value },
      }));
    }
    if (acceptedType2.includes(type) && item === 0) {
      if (recordName === "OPPORTUNITY" && keyName === "scopeofwork") {
        setNetsuiteValue((initialValue) => ({
          ...initialValue,
          OPPORTUNITY: { ...initialValue.OPPORTUNITY, scopeofwork: value },
          AGREEMENT: { ...initialValue.AGREEMENT, scopeofwork: value },
          UTILITY: { ...initialValue.UTILITY, wirescablesunits: value },
        }));
      } else {
        setNetsuiteValue((initialValue) => ({
          ...initialValue,
          [recordName]: { ...initialValue[recordName], [keyName]: value },
        }));
      }
    }
  };

  const searchAgreements = async (value) => {
    setNetsuiteAgreement([]);

    value = value.replace(/t0{0,3}/g, "");

    value = Number(value);

    const data = {
      recordtype: "customrecordagreement",
      fieldId: "id",
      value: value,
    };

    if (value) {
      const response = await search(data);

      if (response?.data?.status === "Success" && response?.data?.message?.length > 0) {
        setNetsuiteAgreement(response.data.message);
      }
    }
  };

  const selectAgreement = (value) => {
    setSelectedAgreement(value.id);
  };

  const handleSupplementChange = (checked) => {
    if (checked) {
      setArrayOfnesuite((value) => {
        return value.map((each) => {
          if (each.recordName === "AGREEMENT") {
            return { ...each, hide: true };
          }
          return each;
        });
      });
      setSupplementAgreement(true);
    } else {
      setArrayOfnesuite((value) => {
        return value.map((each) => {
          if (each.recordName === "AGREEMENT") {
            return { ...each, hide: false };
          }
          return each;
        });
      });
      setSupplementAgreement(false);
    }
  };
  const query = new URLSearchParams(useLocation().search);
  return loading ? (
    <UploadDraftLoading />
  ) : (
    <div>
      <div className="container mt-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div id="files-upload" className="">
                {stage?.currentStep < 9 && CurrentStatus !== "Complete" && (
                  <>
                    <div className="card mb-4 position-relative comment-card">
                      <div className="card-header  border-bottom">
                        <div className="d-flex justify-content-between align-items-center">
                          <h2 className="mb-0">Sign Application</h2>
                          <small className="text-muted">By Applicant</small>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="mb-3">
                          <div className="mb-2 mt-2">
                            {SignerEmail === currentEmail ? (
                              <label className="text-muted d-block form-label d-block">You are authorized to sign the application.</label>
                            ) : (
                              <label className="text-muted d-block form-label d-block">{CurrentStatus === "Complete" ? "Application Signed" : "Who is authorized to sign the application ?"}</label>
                            )}
                            {SignerName && (
                              <>
                                <div className={`${SignerEmail === currentEmail || CurrentStatus === "Complete" ? "alert-success" : "alert-danger"} alert fs-5 mt-3 mb-0 text-center`}>
                                  {CurrentStatus === "Complete"
                                    ? `${SignerName} digitally Signed this Application`
                                    : `Waiting for ${compileValue ? SignerName : ""} to digitally sign this Application.`}
                                </div>
                                {["applicant", "Engineering Representative"].find((each) => each === roleName) && CurrentStatus === "waiting" && (
                                  <div className="d-flex justify-content-evenly p-3 align-items-center">
                                    <div
                                      className="text-muted"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        sendEmailNotification(
                                          {
                                            name: SignerName,
                                            email: SignerEmail,
                                          },
                                          true,
                                        )
                                      }
                                    >
                                      <u>Resend Email</u>
                                    </div>
                                    <div className="input-group w-75" role="group">
                                      <SelectForSignature assignSignee={sendEmailNotification} label="Reselect for Signature" />
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                            <div className="d-flex align-items-center mb-2">
                              {SignerName ? (
                                <div className="d-none"> </div>
                              ) : (
                                <div className="form-check">
                                  <input
                                    className="form-check-input SignByMe"
                                    onClick={() => setMe(true)}
                                    type="radio"
                                    checked={me}
                                    name="flexRadioDefault"
                                    disabled={roleName !== "applicant"}
                                    id="Signedyes"
                                  />
                                  <label className="form-check-label" htmlFor="Signedyes">
                                    Me
                                  </label>
                                </div>
                              )}
                              {SignerName ? (
                                <> </>
                              ) : (
                                <div className="form-check ms-4">
                                  <input
                                    className="form-check-input SignByOthers"
                                    onClick={() => setMe(false)}
                                    disabled={roleName !== "applicant"}
                                    checked={!me}
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="signedno"
                                  />
                                  <label className="form-check-label" htmlFor="signedno">
                                    Others
                                  </label>
                                </div>
                              )}
                            </div>
                          </div>
                          {!me && <SelectForSignature assignSignee={sendEmailNotification} />}
                        </div>
                      </div>
                      {CurrentStatus === "Complete" ? (
                        <div className="card-footer py-0 justify-content-center bg-success">
                          <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                            <span className="d-flex justify-content-between align-items-center">
                              <span>
                                <i className="fal fa-check"></i> Signed
                              </span>
                              <span>
                                By {SignerName} on <small> {SignedDate ? SignedDate : ""}</small>
                              </span>
                            </span>
                          </a>
                        </div>
                      ) : (SignerEmail === currentEmail || !SignerEmail) && me && CurrentStatus !== "Complete" ? (
                        <div className={`${roleName === "applicant" ? "" : "opacity-75"} card-footer justify-content-center bg-primary`} id="footerSign">
                          <div id="signDocu">
                            <a style={roleName === "applicant" ? {} : { pointerEvents: "none" }} onClick={signatureFunction} className="d-block text-white">
                              Sign
                              <i className="fal fa-chevron-right ms-2"></i>
                            </a>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </>
                )}
                {stage?.currentStep >= 7 && CurrentStatus === "Complete" && (
                  <>
                    <div className="card mb-4 position-relative comment-card">
                      <div className="card-header  border-bottom">
                        <div className="d-flex justify-content-between align-items-center">
                          <h2 className="mb-0">Sign Application</h2>
                          <small className="text-muted">By Applicant</small>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="mb-3">
                          <div className="mb-2 mt-2">
                            {SignerEmail === currentEmail ? (
                              <label className="text-muted d-block form-label d-block">You are authorized to sign the application.</label>
                            ) : (
                              <label className="text-muted d-block form-label d-block">{CurrentStatus === "Complete" ? "Application Signed" : "Who is authorized to sign the application ?"}</label>
                            )}
                            {SignerName && (
                              <>
                                <div className={`${SignerEmail === currentEmail || CurrentStatus === "Complete" ? "alert-success" : "alert-danger"} alert fs-5 mt-3 mb-0 text-center`}>
                                  {CurrentStatus === "Complete"
                                    ? `${SignerName} digitally Signed this Application`
                                    : `Waiting for ${compileValue ? SignerName : ""} to digitally sign this Application.`}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="card-footer py-0 justify-content-center bg-success">
                        <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                          <span className="d-flex justify-content-between align-items-center">
                            <span>
                              <i className="fal fa-check"></i> Signed
                            </span>
                            <span>
                              By {SignerName} on <small>{SignedDate ? SignedDate : ""}</small>
                            </span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </>
                )}
                {CurrentStatus === "Complete" && (roleName === "Approving Representative" || roleName === "Engineering Representative" || roleName === "applicant") ? (
                  <div className={CurrentStatus === "Complete" && roleName === "Engineering Representative" ? "card mb-4 shadow " : "card mb-4 shadow border-primary"}>
                    <div className="card-header  border-bottom">
                      <div className="d-flex justify-content-between align-items-center">
                        {CurrentStatus === "Complete" && roleName === "Engineering Representative" ? (
                          <h2 className="mb-0">Validate Application Signature </h2>
                        ) : (
                          <h2 className="mb-0">Validate Application Signature</h2>
                        )}

                        <small className="text-muted">For HNTB</small>
                      </div>
                    </div>
                    <div className="card-body ">
                      <div className="card bg-secondary border-0">
                        <div className="card-body p-3">
                          <div>
                            <span className="mb-0 text-muted">Application</span>
                            {downloadSignedDocuments0 ? (
                              <a target="_blank" href={API_URL + File + (applicationsignedDocumentsEnvelopeId ? "1_" : "") + ItemId + ".pdf"} className="btn btn-link">
                                View
                              </a>
                            ) : (
                              <a
                                href={`${API_URL}/eg001?applicationID=${ItemId}`}
                                className={roleName == "Engineering Representative" && !downloadSignedDocuments0 ? "btn btn-sm btn-primary ml-3" : "btn btn-sm btn-success ml-3 disabled"}
                              >
                                Download Application
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {CurrentStatus === "Complete" && roleName === "Engineering Representative" && validatedby !== "HNTB" ? (
                      <div className="card-footer justify-content-center bg-primary">
                        <a onClick={(e) => validateSign(e.target.value)} className="d-block text-white">
                          Validate
                          <i className="fal fa-chevron-right ms-2"></i>
                        </a>
                      </div>
                    ) : validatedby === "HNTB" && CurrentStatus === "Complete" ? (
                      <div className="d-flex card-footer justify-content-between bg-success" id="footerSign">
                        <div className="justify-content-center bg-success text-white">
                          Validated
                          <i className="fal fa-chevron-right ms-2"></i>
                        </div>
                        <span className="d-block text-white d-flex justify-content-end align-items-center gap-3 text-mute">
                          {""} By HNTB on <small className="d-block text-white"> {validateSignedDate ? validateSignedDate : ""}</small>
                        </span>
                      </div>
                    ) : (
                      <div className="card-footer justify-content-center text-muted" style={{ backgroundColor: "rgb(233 237 241)" }}>
                        Awaiting for Validate
                        <i className="fal fa-chevron-right ms-2"></i>
                      </div>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                {validatedby === "HNTB" && CurrentStatus === "Complete" ? (
                  <div className="card mb-4 border-primary shadow ">
                    <div className="card-header  border-bottom">
                      <div className="d-flex justify-content-between align-items-center">
                        <h2 className="mb-0">Upload Draft Agreement</h2>
                        <small className="text-muted">For HNTB</small>
                      </div>
                    </div>
                    <div className="card-header border-bottom">
                      <p className="mb-0 text-center">
                        <i>
                          <a className="text-primary" data-bs-toggle="modal" data-bs-target="#applicant-detail">
                            <u>Copy applicant details</u>
                          </a>{" "}
                          to draft agreement
                        </i>
                      </p>
                    </div>
                    <div className="card-body">
                      <div className="mb-3">
                        <label className="text-muted d-block">Upload Agreement</label>

                        <div className="input-group mb-3">
                          <input
                            accept=".pdf"
                            type="file"
                            className="form-control"
                            id="aggrement"
                            name="aggrement"
                            disabled={roleName !== "Engineering Representative" || CurrentStatus !== "Complete"}
                            {...register("aggrement", {
                              required: true,
                              onChange: (e) => {
                                const fileName = e.target.files[0].name;
                                if (!checkFileName(fileName)) {
                                  setCharacterError((data) => ({
                                    ...data,
                                    agreement: true,
                                    agreementName: fileName,
                                  }));
                                } else {
                                  setCharacterError((data) => ({
                                    ...data,
                                    agreement: false,
                                    agreementName: "",
                                  }));
                                }
                              },
                            })}
                          />
                          <label className="input-group-text bg-secondary" htmlFor="inputGroupFile02">
                            Upload
                          </label>
                        </div>
                        <span className="text-danger">
                          <span>
                            {errors.aggrement && errors.aggrement.type === "required" && <span>This field is required</span>}
                            {characterError.agreement && <span className="text-sm">Remove special characters from file name, allowed special characters are - and _</span>}
                          </span>
                        </span>
                      </div>
                      <div className="mb-3">
                        <label className="text-muted d-block">Supporting Documents</label>

                        <div className="input-group mb-3">
                          <input
                            type="file"
                            accept=".pdf"
                            multiple
                            className="form-control"
                            id="supporting_doc"
                            name="supporting_doc"
                            disabled={roleName !== "Engineering Representative" || CurrentStatus !== "Complete"}
                            {...register("supporting_doc", {
                              required: true,
                              onChange: (e) => {
                                const fileNameArray = Array.from(e.target.files).map((data) => data.name);
                                const filenames = fileNameArray.filter((data) => !checkFileName(data));
                                if (filenames.length !== 0) {
                                  setCharacterError((data) => ({
                                    ...data,
                                    supporting_doc: true,
                                    supporting_docNames: filenames,
                                  }));
                                } else {
                                  setCharacterError((data) => ({
                                    ...data,
                                    supporting_doc: false,
                                    supporting_docNames: [],
                                  }));
                                }
                              },
                            })}
                          />
                          <label className="input-group-text bg-secondary" htmlFor="inputGroupFile02">
                            Upload
                          </label>
                        </div>
                        <span className="text-danger">
                          <span>
                            {errors.supporting_doc && errors.supporting_doc.type === "required" && <span>This field is required</span>}
                            {characterError.supporting_doc && <span className="text-sm">Remove special characters from file name, allowed special characters are - and _</span>}
                          </span>
                        </span>
                      </div>
                      <div className="mb-3">
                        <label className="text-muted d-block">Enter License Fee</label>
                        <input
                          type="number"
                          className="form-control"
                          id="license_fee"
                          name="license_fee"
                          step=".01"
                          disabled={roleName !== "Engineering Representative" || CurrentStatus !== "Complete"}
                          {...register("license_fee", { required: true })}
                        />
                      </div>
                      <span className="text-danger">
                        <span>{errors.license_fee && <span>This field is required</span>}</span>
                      </span>
                      <div className="mb-3">
                        <Checkbox
                          label="is this supplement agreement?"
                          name="supplement"
                          disabled={roleName !== "Engineering Representative" || CurrentStatus !== "Complete"}
                          value={supplementAgreement}
                          onChange={handleSupplementChange}
                        />
                      </div>
                      {supplementAgreement && (
                        <div className="mb-3">
                          <label className="text-muted d-block">Link the Agreement for supplement</label>
                          <SearchBar
                            values={netsuiteAgreement}
                            onSearch={searchAgreements}
                            onSelectValue={selectAgreement}
                            label={"Agreements"}
                            placeholder="t0005518"
                            showValueObjectName={"fields.name"}
                          />
                        </div>
                      )}

                      {supplementAgreement && selectedAgreement && (
                        <div className="mb-3 ">
                          <label className="text-muted d-block">{"Slected Agreement: t000" + selectedAgreement}</label>
                        </div>
                      )}
                    </div>
                    {(roleName === "Engineering Representative" || roleName === "Approving Representative") && (
                      <div className="card-body">
                        <div className="col-md-12 p-0">
                          <div className="row">
                            <div className="col-lg-12 pt-3 p-0 d-flex flex-row-reverse">
                              <div className="form-check p-0">
                                {!showAll && (
                                  <div className="btn btn-link text-primary text-underline" onClick={() => setShowAll(true)}>
                                    Hide
                                  </div>
                                )}
                                {showAll && (
                                  <div className="btn btn-link text-primary text-underline" onClick={() => setShowAll(false)}>
                                    Show All Default Fields
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {arrayOfnesuite.map((data) => {
                            const { recordName, hide = false } = data;
                            if (hide) return null;
                            return (
                              <>
                                <div className="d-flex items-center justify-between">
                                  <h3 className="pt-3">{data.header}</h3>
                                </div>
                                <div className="row ">
                                  {data.rows.map((datas) =>
                                    datas.columns.map((fiel) => {
                                      let value = netsuiteField?.[recordName]?.[fiel.keyName] || "";
                                      let formupdatevalue = fiel.type === "dropdown" && datas.formType !== formNumber && datas.formType !== undefined;
                                      return (
                                        <>
                                          {fiel.Hide !== "true" && (fiel.defaultDraftShow === true || showAll === false) && (
                                            <div className={formupdatevalue ? "d-none" : fiel.col ? fiel.col : "col-md-6"}>
                                              <FieldAll
                                                data={fiel}
                                                handleNetSuiteChange={handleNetSuiteChange}
                                                recordName={recordName}
                                                col={datas}
                                                value={value}
                                                stage={stage}
                                                formNumber={formNumber}
                                              />
                                            </div>
                                          )}
                                        </>
                                      );
                                    }),
                                  )}
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {/* {custumerID.role === "Approving Representative" && (
                    <div className="card-body">
                      <div className="col-md-12 p-0">
                        <div className="row">
                          <div className="col-lg-12 pt-3 p-0 d-flex flex-row-reverse">
                            <div className="form-check p-0">
                              {!showAll && (
                                <div
                                  className="btn btn-link text-primary text-underline"
                                  onClick={() => setShowAll(true)}
                                >
                                  Hide
                                </div>
                              )}
                              {showAll && (
                                <div
                                  className="btn btn-link text-primary text-underline"
                                  onClick={() => setShowAll(false)}
                                >
                                  Show All Default Fields
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {arrayOfnesuite.map((data) => {
                          const { recordName } = data;
                          return (
                            <>
                              <h3 className="pt-3">{data.header} </h3>
                              <div className="row ">
                                {data.rows.map((datas, ind) =>
                                  datas.columns.map((fiel, ind) => {
                                    let value =
                                      netsuiteField?.[recordName]?.[
                                        fiel.keyName
                                      ] || "";
                                    let formupdatevalue =
                                      fiel.type === "dropdown" &&
                                      datas.formType !== formNumber &&
                                      datas.formType !== undefined;
                                    return (
                                      <>
                                        {fiel.Hide !== "true" &&
                                          (fiel.defaultDraftShow === true ||
                                            showAll === false) && (
                                            <div
                                              className={
                                                formupdatevalue
                                                  ? "d-none"
                                                  : fiel.col
                                                  ? fiel.col
                                                  : "col-md-6"
                                              }
                                            >
                                              <FieldAll
                                                data={fiel}
                                                handleNetSuiteChange={
                                                  handleNetSuiteChange
                                                }
                                                recordName={recordName}
                                                col={datas}
                                                value={value}
                                                stage={stage}
                                                formNumber={formNumber}
                                              />
                                            </div>
                                          )}
                                      </>
                                    );
                                  })
                                )}
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  )} */}

                    {roleName !== "Engineering Representative" || CurrentStatus !== "Complete" ? (
                      <div className="card-footer justify-content-center " style={{ backgroundColor: "rgb(233 237 241)" }}>
                        Awaiting for HNTB
                        <i className="fal fa-chevron-right ms-2"></i>
                      </div>
                    ) : (
                      <div className="card-footer bg-primary" style={{ alignItems: "initial" }}>
                        <button disabled={roleName !== "Engineering Representative"} className="d-block text-white" style={{ border: "none", backgroundColor: "#5E74E4" }}>
                          {roleName !== "Engineering Representative" ? "Awaiting for HNTB" : "Upload and Send to NCRR"} <i className="fal fa-chevron-right ms-2"></i>
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  <> </>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
      <ApplicantDetailsModel />
      <AddContactModal assignSignee={sendEmailNotification} />
    </div>
  );
}

const SelectForSignature = ({ assignSignee, label = "Select contact for Signature" }) => {
  const application = useApplication();
  const user = useUser();
  const [facilityContacts, setFacilityContacts] = useState([]);
  const [consultantContacts, setConsultantContacts] = useState([]);
  const [applicationContacts, setApplicationContacts] = useState([]);

  const [selectedContact, setSelectedContact] = useState(null);

  const getFacilityContacts = async (companyID, applicantDetails) => {
    const res = await getContactsByCompanyId(companyID);
    if (res.data.status) {
      const contacts = res.data.data;
      const { primaryContact, billingContact, consultantContact, signatoryContact, engineeringContact } = applicantDetails;
      const filteredContacts = contacts.filter((each) => {
        if (each.id === primaryContact?.id) {
          return false;
        }
        if (each.id === billingContact?.id) {
          return false;
        }
        if (each.id === consultantContact?.id) {
          return false;
        }
        if (each.id === engineeringContact?.id) {
          return false;
        }
        if (each.id === signatoryContact?.id) {
          return false;
        }
        return true;
      });
      setFacilityContacts(filteredContacts);
    }
  };

  const getConsultantContacts = async (companyID, applicantDetails) => {
    const res = await getContactsByCompanyId(companyID);
    if (res.data.status) {
      const contacts = res.data.data;
      const { primaryContact, billingContact, consultantContact, signatoryContact, engineeringContact } = applicantDetails;
      const filteredContacts = contacts.filter((each) => {
        if (each.id === primaryContact?.id) {
          return false;
        }
        if (each.id === billingContact?.id) {
          return false;
        }
        if (each.id === consultantContact?.id) {
          return false;
        }
        if (each.id === engineeringContact?.id) {
          return false;
        }
        if (each.id === signatoryContact?.id) {
          return false;
        }
        return true;
      });
      setConsultantContacts(filteredContacts);
    }
  };

  const getApplicationContacts = (applicantDetails) => {
    const { primaryContact, billingContact, consultantContact, signatoryContact, engineeringContact } = applicantDetails;
    const applicationContacts = [];

    if (billingContact) {
      if (!applicationContacts.some((each) => each.id === billingContact.id)) {
        applicationContacts.push(billingContact);
      }
    }
    if (primaryContact) {
      if (!applicationContacts.some((each) => each.id === primaryContact.id)) {
        applicationContacts.push(primaryContact);
      }
    }
    if (consultantContact) {
      if (!applicationContacts.some((each) => each.id === consultantContact.id)) {
        applicationContacts.push(consultantContact);
      }
    }
    if (signatoryContact) {
      if (!applicationContacts.some((each) => each.id === signatoryContact.id)) {
        applicationContacts.push(signatoryContact);
      }
    }
    if (engineeringContact) {
      if (!applicationContacts.some((each) => each.id === engineeringContact.id)) {
        applicationContacts.push(engineeringContact);
      }
    }
    setApplicationContacts(applicationContacts);
  };

  useEffect(() => {
    if (application) {
      const { consultantId, facilityId, applicantDetails } = application;
      if (consultantId) {
        getConsultantContacts(consultantId, applicantDetails);
      }
      if (facilityId) {
        getFacilityContacts(facilityId, applicantDetails);
      }
      if (applicantDetails) {
        getApplicationContacts(applicantDetails);
      }
    }
  }, [application]);

  if (!application || !user) {
    return <DashBoardLoading />;
  }

  const handleSelectChange = (id) => {
    let contact = null;

    if (id === "NEW") {
      setSelectedContact(id);
      return;
    }

    if (!contact) {
      contact = applicationContacts.find((each) => each.id === id);
    }

    if (!contact) {
      contact = facilityContacts.find((each) => each.id === id);
    }
    if (!contact) {
      contact = consultantContacts.find((each) => each.id === id);
    }

    setSelectedContact(contact);
  };

  return (
    <div className="card bg-secondary border-0">
      <div id="div1" className="card-body p-3">
        <div>
          <div className="input-group" role="group">
            <select className="form-select" id="search-select" onChange={(e) => handleSelectChange(e.target.value)} aria-label="Default select example">
              <option value="" selected disabled>
                {label}
              </option>
              <optgroup
                label="Application Contacts"
                style={{
                  color: "gray",
                }}
              >
                {applicationContacts.map((each) => {
                  return <option key={each?.id} value={each?.id}>{`${each.name} (${each?.email})`}</option>;
                })}
              </optgroup>
              {facilityContacts.length > 0 && (
                <optgroup
                  label="Facility Contacts"
                  style={{
                    color: "gray",
                  }}
                >
                  {facilityContacts.map((each) => {
                    return <option key={each?.id} value={each?.id}>{`${each.name} (${each?.email})`}</option>;
                  })}
                </optgroup>
              )}
              {consultantContacts.length > 0 && (
                <optgroup
                  label="Engineering Contacts"
                  style={{
                    color: "gray",
                  }}
                >
                  {consultantContacts.map((each) => {
                    return <option key={each?.id} value={each?.id}>{`${each.name} (${each?.email})`}</option>;
                  })}
                </optgroup>
              )}
              <optgroup
                label="New"
                style={{
                  color: "gray",
                }}
              >
                <option value={"NEW"}>Add New Contact</option>
              </optgroup>
            </select>
            {selectedContact && selectedContact !== "NEW" && (
              <div id="hidden_div" className="dropdown-content-bond">
                <button
                  type="button"
                  className="btn btn-success"
                  style={{
                    borderRadius: "0 6px 6px 0",
                    height: "100%",
                    paddingBottom: "0.7rem !important",
                    paddingTop: " 0.75rem !important",
                  }}
                  onClick={() => assignSignee(selectedContact)}
                >
                  Send
                </button>
              </div>
            )}
            {selectedContact === "NEW" && (
              <div id="hidden_div2" className="dropdown-content-bond">
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop22"
                  className="btn btn-success"
                  style={{
                    borderRadius: "0 6px 6px 0",
                    height: "100%",
                    paddingBottom: "0.7rem !important",
                    paddingTop: " 0.75rem !important",
                  }}
                >
                  Add
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const AddContactModal = ({ assignSignee }) => {
  const application = useApplication();
  const user = useUser();
  const [contact, setContact] = useState({
    name: "",
    email: "",
    role: "",
  });
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (user) {
      const { company } = user;
      if (company) {
        const { companyType } = company;
        if (companyType === "FACILITY") {
          const roles = [
            {
              name: "role",
              value: "Primary Representative",
              label: "Primary Representative",
            },
            {
              name: "role",
              value: "Billing Representative",
              label: "Billing Representative",
            },
          ];
          setRoles(roles);
        }

        if (companyType === "CONSULTANT") {
          const roles = [
            {
              name: "role",
              value: "Primary Representative",
              label: "Primary Representative",
            },
            {
              name: "role",
              value: "Billing Representative",
              label: "Billing Representative",
            },
            {
              name: "role",
              value: "Engineering Representative",
              label: "Engineering Representative",
            },
          ];
          setRoles(roles);
        }
      }
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!contact.name || !contact.email || !contact.role) {
      return;
    }

    const { facilityId, consultantId } = application;
    const { name, email, role } = contact;

    const data = {
      name,
      jobTitle: "",
      email,
      phone: "",
      role: [role],
      default: false,
      address: {
        address: "",
        city: "",
        state: "",
        zip: "",
        poBox: "",
        country: "United States",
      },
    };

    if (role === "Primary Representative" || role === "Billing Representative") {
      data["companyId"] = facilityId;
    }

    if (role === "Engineering Representative") {
      data["companyId"] = consultantId;
    }

    await createContact(data);
    await assignSignee(data);
  };

  const handleChange = (e) => {
    if (e?.name) {
      setContact({ ...contact, [e.name]: e.value });
      return;
    }
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  return (
    <div className="modal fade" id="staticBackdrop22" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <label htmlFor="">Add Contact</label>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input required={true} type="text" name="name" onChange={handleChange} className="form-control mb-3" id="name" placeholder="Enter Name" />
              <label htmlFor="email" className="form-label">
                Email address
              </label>
              <input required={true} type="email" name="email" onChange={handleChange} className="form-control" id="email" placeholder="Enter Email Address" />
              <div className="mt-3">
                <label className="form-label d-flex justify-content-between">Role</label>
                <Select placeholder="Select Roles" required={true} onChange={handleChange} options={roles} />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
              <button type="submit" className="btn btn-success">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
