import { REACT_APP_NS_initial_fee_original, REACT_APP_NS_risk_finance_fee_original } from "../api";
import { isExpeditedApplication } from "./checkIsExpeditedApplication";

export const getApplicationPaymentFee = async (application) => {
  if (!application) {
    return null;
  }
  let applicationFee = 1500;
  let engineeringFee = 2200;
  let transactionFee = 111;
  let totalAmountForStripe = applicationFee + engineeringFee + transactionFee;
  let totalAmountForOfflinePayment = applicationFee + engineeringFee;

  const { event1PaymentAmount, event1applicationFee, event1engineeringFee, event1transactionFee = "121.9", event1paymentOn, applicationStage } = application;

  let submittedDate;

  if (event1paymentOn) {
    submittedDate = new Date(event1paymentOn);
  }

  if (!event1paymentOn) {
    if (applicationStage < 6 || applicationStage === 26) {
      submittedDate = new Date();
    }

    if (applicationStage >= 6 && applicationStage !== 26) {
      submittedDate = new Date(2023, 5, 6);
    }
  }

  if (event1PaymentAmount && event1applicationFee && event1engineeringFee && event1transactionFee) {
    return {
      applicationFee: event1applicationFee,
      engineeringFee: event1engineeringFee,
      transactionFee: event1transactionFee,
      submittedDate,
      totalAmountForStripe: event1applicationFee + event1engineeringFee + event1transactionFee,
      totalAmountForOfflinePayment: event1applicationFee + event1engineeringFee,
    };
  }

  if (submittedDate.getFullYear() >= 2024) {
    applicationFee = 1700;
    engineeringFee = 2350;
    transactionFee = 121.9;

    // if (isExpeditedApplication(application)) {
    //   applicationFee = 1800;
    //   engineeringFee = 2450;
    //   transactionFee = 131.9;
    // }

    totalAmountForStripe = applicationFee + engineeringFee + transactionFee;
    totalAmountForOfflinePayment = applicationFee + engineeringFee;
  }

  return {
    applicationFee,
    engineeringFee,
    transactionFee,
    totalAmountForStripe,
    totalAmountForOfflinePayment,
  };
};

export const getCompilePaymentFee = (application) => {
  const { agreement, payment, event2paymentType } = application || {};
  const { draft = [], compiled = [] } = agreement || {};
  const { event2StripeResponse = [] } = payment || {};

  const license_fee_draft = draft.find((each) => each.action === "Upload and Send to NCRR" && each.role === "Engineering Representative");
  const risk_finance_fee_compiled = compiled.find((each) => each.action === "Sign and Paid" && each.role === "applicant");

  const license_fee = license_fee_draft?.licenseFee[0]?.license_fee || 0;
  const risk_fee = risk_finance_fee_compiled?.riskFinanceFee === "true";

  const nsr_application_fee = Number(REACT_APP_NS_initial_fee_original);

  const onlinePayment = event2StripeResponse.find((each) => each?.payment_status === "paid") ? true : event2paymentType === "Stripe" ? true : false;

  let totalPaymentFee = Number(nsr_application_fee) + Number(license_fee);
  let riskFinanceFee = 0;

  if (risk_fee) {
    riskFinanceFee += Number(REACT_APP_NS_risk_finance_fee_original);
    totalPaymentFee += Number(riskFinanceFee);
  }

  const initial_fee_transaction_fee = 6;
  const risk_finance_fee_transaction_fee = 57;
  const license_fee_transaction_fee = Math.round(license_fee * 0.0301);
  const transactionFee = initial_fee_transaction_fee + risk_finance_fee_transaction_fee + license_fee_transaction_fee;

  const totalOfflinePaymentFee = totalPaymentFee;
  if (onlinePayment) {
    totalPaymentFee += Number(transactionFee);
    totalPaymentFee = totalPaymentFee.toFixed(2);
  }

  return { license_fee, riskFinanceFee, nsr_application_fee, totalPaymentFee, transactionFee, totalOfflinePaymentFee };
};

export const getApplicationPaymentForDashboard = (application) => {
  let amount = "";

  let submittedDate;

  if (application.event1paymentOn) {
    submittedDate = new Date(application.event1paymentOn);
  } else {
    if (application.applicationStage < 6 || application.applicationStage === 26) {
      submittedDate = new Date();
    }

    if (application.applicationStage >= 6 && application.applicationStage !== 26) {
      submittedDate = new Date(2023, 5, 6);
    }
  }

  const event1StripeResponseArray = application.payment.event1StripeResponse || [];

  let event1StripeResponse = event1StripeResponseArray.some((each) => each.payment_status === "paid") || false;

  if (application.event1paymentType || event1StripeResponse) {
    if (submittedDate.getFullYear() >= 2024) {
      amount = `$ ` + 4050;

      // if (isExpeditedApplication(application)) {
      //   amount = `$ ` + 4250;
      // }
    } else {
      amount = `$ ` + 3700;
    }
  } else if (application.applicationStage === 25 && !event1StripeResponse) {
    amount = `$ ` + 3700;
  }

  if (amount) {
    if (application.event1paymentOn) {
      amount += ` - ${new Date(application.event1paymentOn).toLocaleDateString()}`;
    } else {
      amount += " - Not Available";
    }
  }
  return amount;
};

export const getCompiledAmountForDashboard = (application) => {
  // 2nd payment
  let amount = "";
  let { agreement } = application;
  let chargesfee = agreement.compiled && agreement.compiled[1] && agreement.compiled[1].riskFinanceFee === "true";

  const event2StripeResponseArray = application.payment.event2StripeResponse || [];

  let event2StripeResponse = event2StripeResponseArray.some((each) => each.payment_status === "paid") || false;

  //  LicenseFee value
  let event2Licensefee = agreement.draft.map((each) => each) || {};

  const licenseFees = event2Licensefee.map((item) => {
    if (item.hasOwnProperty("licenseFee") && Array.isArray(item.licenseFee)) {
      const validLicenseFees = item.licenseFee.filter((license) => typeof license === "object" && license.hasOwnProperty("license_fee"));
      return validLicenseFees.map((license) => license.license_fee);
    }
    return [];
  });

  const filteredLicenseFees = licenseFees.flat().filter((value) => value.trim() !== "");
  const initial_fee = Number(REACT_APP_NS_initial_fee_original);
  const license_fee = Number(filteredLicenseFees[0]);
  const risk_finance_fees = chargesfee ? Number(REACT_APP_NS_risk_finance_fee_original) : 0;

  if (application.event2paymentType || event2StripeResponse) {
    amount = `$ ` + Number(initial_fee + risk_finance_fees + license_fee);
  }

  if (amount) {
    if (application.event2paymentOn) {
      amount += ` - ${new Date(application.event2paymentOn).toLocaleDateString()}`;
    } else {
      amount += " - Not Available";
    }
  }

  return amount;
};
