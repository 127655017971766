/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CompiledAgreement from "./CompiledAgreement";
import FinalRelease from "./FinalRelease";
import UploadInsurance from "./UploadInsurance";
import UploadLetterAndValidate from "./UploadLetterAndValidate";
import ValidateAndSign from "./ValidateAndSign";
import ValidateFee from "./ValidateFee";
import { useHistory } from "react-router";
import queryString from "query-string";
import { compileAgreement, getLicenseStatus, getcurrentStatus, putNextStatus, getFormData, putenvelopeId, sendNotify, validatePayment } from "../../api/currentApplications";
import { API_URL, REACT_APP_URL } from "../../api";
import CommonNavigation from "../commonNavigation/CommonNavigation";
import PromptComponent from "../promptComponent/PromptComponent";
import { useSelector } from "react-redux";
import checkFileName from "../../utils/checkSpecialCharacter";
import { checkEncrypted } from "../../utils/checkEncrypted";
import { useAtoms } from "../../hooks/useAtoms";
import { useUser } from "../../hooks/useUser";
export default function CompiledLicenseAgreement(props) {
  let history = useHistory();
  const selectedOperatingSignee = useSelector((state) => state.selectedOperatingSignee);

  const [stage, setStage] = useState("");
  const [loading, setLoading] = useState(true);
  let ItemId = props.match.params.applicationID;
  const { setNavigation, setHideNavigation } = useAtoms();
  setHideNavigation(false);
  let qs = queryString.parse(window.location.search);
  const [formName, setFormName] = useState("");
  const [companyDetails, setCompanyData] = useState();
  const [compileValue, setCompileValue] = useState([]);
  const [applicationTitle, setApplicationTitle] = useState();
  const [laodSubmit, setLoadSubmit] = useState(false);
  setNavigation(`Compile Agreement ${" "} ${applicationTitle ? applicationTitle : ItemId} `);
  const user = useUser() || {};
  const { contact = {}, roleName = "" } = user;

  useEffect(() => {
    const fetchData = async () => {
      let response = await getLicenseStatus(ItemId);
      setCompileValue(response.data.data);
    };
    fetchData();
  }, []);

  let { compiled } = compileValue || [];
  let compiles0 = (compiled && compiled[0]) || [];
  let { formData } = compiles0 || {};
  let filedata = formData && formData[0];
  let { fileNameUpdated, signedDocumentsEnvelopeId } = filedata || "";

  const signatureFunction = async (signValues) => {
    setLoading(true);
    let { signerName, signerEmail } = signValues || {};
    let dsReturnUrl = `${REACT_APP_URL}/agreementSignatureWaiting/${ItemId}`;
    let envelopeIdStr = "";
    let fileNameNumber = "";
    let envelopeId = "";

    if (signedDocumentsEnvelopeId && signedDocumentsEnvelopeId.length > 0) {
      envelopeId = signedDocumentsEnvelopeId[signedDocumentsEnvelopeId.length - 1];
      fileNameNumber = signedDocumentsEnvelopeId.length + "_";
      envelopeIdStr = `&envelopeId=${envelopeId}`;
    }

    window.location = `${API_URL}/eg001?signerName=${signerName}&signerEmail=${signerEmail}&pdfFile=${fileNameNumber + fileNameUpdated}&dsReturnUrl=${dsReturnUrl}` + envelopeIdStr;
    return;
  };

  useEffect(() => {
    const fetchData = async () => {
      await getFormData(ItemId).then(({ data }) => {
        setFormName(data.formName);
        setApplicationTitle(data.ApplicationTitle);
      });
      let newRes = await getcurrentStatus(ItemId);
      setLoading(false);
      setStage(newRes.data.data);
    };
    fetchData();
  }, []);

  const compiledAgreementdata = async () => {
    const fileNames = [];
    const license_file = document.getElementById("License_Agreement").files[0];

    const isEncrypted = await checkEncrypted(license_file);
    if (!isEncrypted) {
      alert("The agreement you have uploaded appears to be encrypted. Please upload a different file, as it may affect the addition of dates after the signatures.");

      return;
    }

    setLoadSubmit(true);

    var filename = license_file.name;

    fileNames.push(filename);

    const Payment_Instructions_file = document.getElementById("Payment_Instructions").files[0];
    var Payment_Instructions_filename = Payment_Instructions_file.name || "";

    fileNames.push(Payment_Instructions_filename);

    const formData = new FormData();
    formData.append("action", "Uploaded");
    formData.append("applicationID", ItemId);
    formData.append("role", roleName);
    formData.append("date", new Date());
    formData.append("licensefile", license_file, filename);
    formData.append("Payment_Instructions", Payment_Instructions_file, Payment_Instructions_filename);

    let licenseData = [
      {
        fileLabel: document.getElementById("License_Agreement").name,
        fileNameOriginal: filename,
        fileName: "",
      },
      {
        fileLabel: document.getElementById("Payment_Instructions").name,
        fileNameOriginal: Payment_Instructions_filename,
        fileName: "",
      },
    ];
    formData.append("licenseData", JSON.stringify(licenseData));
    const FileNameResult = checkFileName(fileNames);
    setLoading(true);
    if (FileNameResult === true) {
      let response = await compileAgreement(formData);
      if (stage.currentStage != 23 && stage.currentStage != 4) {
        let newRes = await putNextStatus(ItemId, 1);
        setLoadSubmit(false);
        setStage(newRes.data.data);
      }
    } else {
      alert(`Please Change the Names Of This File Before Submiting ${FileNameResult.toString()}`);
      window.location.reload();
    }
    setLoading(false);
  };

  const uploadInsurancedata = async (insurence) => {
    setLoadSubmit(true);
    if (document.getElementById("optionOne").checked !== true && document.getElementById("Certificates_of_Insurance_1") && document.getElementById("Certificates_of_Insurance_2")) {
      const fileNames = [];
      const license_file_1 = document.getElementById("Certificates_of_Insurance_1").files[0];
      const license_file_2 = document.getElementById("Certificates_of_Insurance_2").files[0];

      var filename1 = license_file_1.name || "";
      var filename2 = license_file_2.name || "";
      fileNames.push(filename1, filename2);
      const formData = new FormData();
      formData.append("action", "Sign and Paid");
      formData.append("applicationID", ItemId);
      formData.append("Risk_Finance", localStorage.getItem("Risk_Finance"));
      formData.append("role", roleName);
      formData.append("date", new Date());
      formData.append("Certificates_of_Insurance_1", license_file_1, filename1);
      formData.append("Certificates_of_Insurance_2", license_file_2, filename2);

      let licenseData = [
        {
          fileLabel: document.getElementById("Certificates_of_Insurance_1").name,
          fileNameOriginal: filename1,
          fileName: "",
        },
        {
          fileLabel: document.getElementById("Certificates_of_Insurance_2").name,
          fileNameOriginal: filename2,
          fileName: "",
        },
        {
          esignatureRequired: true,
        },
      ];

      formData.append("licenseData", JSON.stringify(licenseData));
      const FileNameResult = checkFileName(fileNames);
      setLoading(true);
      if (FileNameResult === true) {
        if (document.getElementById("flexSwitchCheckChecked").checked === true) {
          await compileAgreement(formData);
          let newRes = await putNextStatus(ItemId, 1);
          setStage(newRes.data.data);
          window.location.reload();
        } else {
          alert("please Check Either Option 1 Or Option 2");
          window.location.reload();
        }
      } else {
        alert(`Please Change the Names Of This File Before Submiting ${FileNameResult.toString()}`);
        window.location.reload();
      }
      setLoading(false);
    } else if (
      document.getElementById("Certificates_of_Insurance_1") &&
      document.getElementById("Certificates_of_Insurance_2") &&
      document.getElementById("Certificates_of_Insurance_3") &&
      document.getElementById("Certificates_of_Insurance_4")
    ) {
      const fileNames = [];

      const license_file_1 = document.getElementById("Certificates_of_Insurance_1").files[0];
      const license_file_2 = document.getElementById("Certificates_of_Insurance_2").files[0];
      const license_file_3 = document.getElementById("Certificates_of_Insurance_3").files[0];
      const license_file_4 = document.getElementById("Certificates_of_Insurance_4").files[0];
      var filename1 = license_file_1.name || "";
      var filename2 = license_file_2.name || "";
      var filename3 = license_file_3.name || "";
      var filename4 = license_file_4.name || "";

      fileNames.push(filename1, filename2, filename3, filename4);

      const formData = new FormData();
      formData.append("action", "Sign and Paid");
      formData.append("applicationID", ItemId);
      formData.append("Risk_Finance", localStorage.getItem("Risk_Finance"));
      formData.append("role", roleName);
      formData.append("date", new Date());
      formData.append("Certificates_of_Insurance_1", license_file_1, filename1);
      formData.append("Certificates_of_Insurance_2", license_file_2, filename2);
      formData.append("Certificates_of_Insurance_3", license_file_3, filename3);
      formData.append("Certificates_of_Insurance_4", license_file_4, filename4);

      let licenseData = [
        {
          fileLabel: document.getElementById("Certificates_of_Insurance_1").name,
          fileNameOriginal: filename1,
          fileName: "",
        },
        {
          fileLabel: document.getElementById("Certificates_of_Insurance_2").name,
          fileNameOriginal: filename2,
          fileName: "",
        },
        {
          fileLabel: document.getElementById("Certificates_of_Insurance_3").name,
          fileNameOriginal: filename3,
          fileName: "",
        },
        {
          fileLabel: document.getElementById("Certificates_of_Insurance_4").name,
          fileNameOriginal: filename4,
          fileName: "",
        },
        {
          esignatureRequired: true,
        },
      ];
      formData.append("licenseData", JSON.stringify(licenseData));

      const FileNameResult = checkFileName(fileNames);
      setLoading(true);
      if (FileNameResult === true) {
        await compileAgreement(formData);
        let newRes = await putNextStatus(ItemId, 1);
        setStage(newRes.data.data);
        window.location.reload();
      } else {
        alert(`Please Change the Names Of This File Before Submiting ${FileNameResult.toString()}`);
        window.location.reload();
      }
      setLoading(false);
    }
  };

  const validateFee = async () => {
    setLoadSubmit(true);
    const formData = new FormData();
    formData.append("action", "Validate");
    formData.append("applicationID", ItemId);
    formData.append("role", roleName);
    formData.append("date", new Date());
    let licenseData = [{ esignatureRequired: true }];
    formData.append("licenseData", JSON.stringify(licenseData));
    await compileAgreement(formData);
    let data = {
      applicationID: ItemId,
      signerEmail: ["Test"],
      LinkUrl: process.env.REACT_APP_URL + window.location.pathname,
      validatedby: "NCRR",
      agreementType: "CompiledAgreement",
    };
    let datas = await sendNotify(data);
    if (stage.currentStage != 23 && stage.currentStage != 4) {
      let newRes = await putNextStatus(ItemId, 1);
      setLoadSubmit(false);
      setStage(newRes.data.data);
    }
  };

  const uploadLetterAndValidate = async () => {
    setLoadSubmit(true);

    const Construction_Approval_Letter_Uploaded_Element = document.getElementById("Construction_Approval_Letter_Uploaded");

    const Construction_Approval_Letter_Uploaded =
      Construction_Approval_Letter_Uploaded_Element && Construction_Approval_Letter_Uploaded_Element.value ? Construction_Approval_Letter_Uploaded_Element.value : null;
    if (Construction_Approval_Letter_Uploaded) {
      //If Construction_Approval_Letter_Uploaded
      const formData = new FormData();
      formData.append("action", "Validate and sign");
      formData.append("applicationID", ItemId);
      // formData.append("OperatingSignee", selectedOperatingSignee);
      formData.append("role", roleName);
      formData.append("date", new Date());
      let licenseData = [{ esignatureRequired: true }];
      formData.append("licenseData", JSON.stringify(licenseData));
      setLoading(true);
      let response = await compileAgreement(formData);
      const { name, email } = contact;
      let signValues = {
        signerName: name || "Operating Signee",
        signerEmail: email,
      };
      await signatureFunction(signValues);
    } else {
      const license_file = document.getElementById("Construction_Approval_Letter").files[0];
      const license_file1 = document.getElementById("Recent_Insurance_Approvals").files[0];
      var filename1 = license_file1.name;
      var filename = license_file.name;
      const formData = new FormData();
      formData.append("action", "Uploaded");
      formData.append("OperatingSignee", JSON.stringify(selectedOperatingSignee));
      formData.append("applicationID", ItemId);
      formData.append("role", roleName);
      formData.append("date", new Date());
      formData.append("licensefile", license_file, filename);
      formData.append("licensefile1", license_file1, filename1);
      let licenseData = [
        {
          fileLabel: document.getElementById("Construction_Approval_Letter").name,
          fileNameOriginal: filename,
          fileName: "",
        },
        {
          fileLabel: document.getElementById("Recent_Insurance_Approvals").name,
          fileNameOriginal: filename1,
          fileName: "",
        },
      ];
      formData.append("licenseData", JSON.stringify(licenseData));
      setLoading(true);
      await compileAgreement(formData);
      await putNextStatus(ItemId, 1);
      setLoading(false);
      setLoadSubmit(false);
      window.location.reload();
    }
  };

  const validateAndSign = async () => {
    setLoadSubmit(true);
    const formData = new FormData();
    formData.append("action", "Validate and sign");
    formData.append("applicationID", ItemId);
    formData.append("role", roleName);
    formData.append("date", new Date());
    let licenseData = [{ esignatureRequired: true }];
    formData.append("licenseData", JSON.stringify(licenseData));
    setLoading(true);
    let response = await compileAgreement(formData);
    let data = response.data || {};
    let { responseData } = data || {};
    let { agreement } = responseData || {};
    let { compiled } = agreement || [];
    let value = compiled && compiled[1];
    let { formData: formValues } = value || {};
    let fileNameFind = formValues && formValues[0];
    let { fileNameUpdated: fileNameForPDF } = fileNameFind;
    const { name, email } = contact;
    let signValues = {
      signerName: name || "Signing Representative",
      signerEmail: email,
      aa: fileNameForPDF,
    };
    await signatureFunction(signValues);
  };

  const finalRelease = async () => {
    setLoadSubmit(true);
    const license_file = document.getElementById("sealed_certification").files[0];
    var filename = license_file.name;
    const newFile = document.getElementById("final_application_approval_letter").files[0];
    var newFileName = newFile.name;
    const formData = new FormData();
    formData.append("action", "Send");
    formData.append("applicationID", ItemId);
    formData.append("role", roleName);
    formData.append("date", new Date());
    formData.append("sealed_certification", license_file, filename);
    formData.append("final_application_approval_letter", newFile, newFileName);
    let licenseData = [
      {
        fileLabel: document.getElementById("sealed_certification").name,
        fileNameOriginal: filename,
        fileName: "",
      },
      {
        fileLabel: document.getElementById("final_application_approval_letter").name,
        fileNameOrginal: newFileName,
        fileName: "",
      },
    ];
    formData.append("licenseData", JSON.stringify(licenseData));
    await compileAgreement(formData);
    if (stage.currentStage === "Final Release") {
      let newRes = await putNextStatus(ItemId, 1);
      setLoadSubmit(false);
      setStage(newRes.data.data);
      if (newRes.data.data.currentStage === "Completed") {
        history.push(`/finalRelease/${ItemId}`);
      }
    }
  };

  let OperatingSigneeData = (compiled && compiled[5]) || [];
  let { action: OperatingSigneeaction } = OperatingSigneeData || {};

  let compiles5 = (compiled && (OperatingSigneeaction == "Send" ? compiled[5] : compiled[6])) || [];

  let { action: action5 } = compiles5 || {};

  return (
    <div>
      <div className={laodSubmit ? "submitting" : null}></div>
      <div className="border-bottom mb-4 bg-white print">
        <div className="w-75 mx-auto">
          <div className="">
            <CommonNavigation ItemId={ItemId} />
          </div>
        </div>
      </div>
      <PromptComponent applicationId={ItemId} />
      {stage.currentStage === "Compile License Agreement" ? (
        <CompiledAgreement compiledAgreementdata={compiledAgreementdata} ItemId={ItemId} />
      ) : (
        <div> {stage.currentStep < 10 ? <h2 className="col d-flex justify-content-center">Please complete the previous steps</h2> : null} </div>
      )}
      {stage.currentStage === "Upload Insurance" ? (
        <UploadInsurance uploadInsurancedata={uploadInsurancedata} ItemId={ItemId} companyDetails={companyDetails} signingFunction={signatureFunction} />
      ) : null}
      {stage.currentStage === "Validate Uploaded Insurance" ||
      (stage.currentStage === "Signature" && stage.id === 20) ||
      stage.currentStage === "Compile Agreement Assign Signee" ||
      stage.currentStage === "Validate Fee, Insurance and Agreement" ||
      (stage.currentStage === "Payment" && stage.id === 18) ? (
        <ValidateFee validateFee={validateFee} ItemId={ItemId} currentStageData={stage} signingFunction={signatureFunction} />
      ) : null}
      {stage.currentStage === "Validate and Sign" ? <ValidateAndSign validateAndSign={validateAndSign} ItemId={ItemId} /> : null}
      {stage.currentStage === "Upload Letter and Validate" || (stage.id === 24 && stage.currentStage === "Signature") ? (
        <UploadLetterAndValidate uploadLetterAndValidate={uploadLetterAndValidate} ItemId={ItemId} />
      ) : null}
      {stage.currentStage === "Final Release" || action5 === "Send" ? <FinalRelease finalRelease={finalRelease} ItemId={ItemId} /> : null}
    </div>
  );
}
