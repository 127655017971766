import React, { useEffect, useState } from "react";
import images from "../../images/print-logo.png";
import toast from "react-hot-toast";
import Select from "react-select";
import { putNextStatus, updatePaymentDetails, putPaymentType, approvepayment, getACHNumberEmails, assignPaymentApi } from "../../api/currentApplications";
import { API_URL, PAYMENT_LINK, REACT_APP_URL } from "../../api";
import { Netsuite } from "../../schema/NetSuiteSchema";
import { getNetsuiteInitialValue, netSuiteUpsertRecords } from "../../api/Netsuite";
import { getCompilePaymentFee } from "../../utils/getPaymentAmount";
import { useAtoms } from "../../hooks/useAtoms";
import { useUser } from "../../hooks/useUser";
import { useApplication } from "../../hooks/useApplication";
import { createContact, getContactsByCompanyId } from "../../api/crm";
import DashBoardLoading from "../loaders/DashBoardLoading";
import { PaymentInformation } from "./PaymentInformation";
import { OldApplication } from "./OldApplication";
import { AuthorizeAgreementFee } from "./AuthorizeAgreementFee";

const CompiledPayment = () => {
  const { contact = {} } = useUser() || {};
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [records, setRecords] = React.useState([]);

  const [editPaymentNotes, setEditPaymentNotes] = useState(false);
  const [oldApplication, setOldApplication] = useState(false);
  const { setHideNavigation } = useAtoms();
  const [netsuiteField, setNetsuiteValue] = React.useState({
    CASHSALE2: {},
    VENDORBILL2: {},
    CUSTOMERDEPOSIT: {},
  });
  const application = useApplication();
  const { _id } = application;
  setHideNavigation(false);

  const getApplicantEmail_ACH_Number_Not_Exist = async () => {
    try {
      const res = await getACHNumberEmails();
      setEmails(res?.data?.data || []);
    } catch (error) {
      setEmails([]);
    }
  };

  const updatePaymentType = async (paymentStep = "event2paymentType", paymentType) => {
    let data = {
      applicationID: _id,
      paymentStep: paymentStep,
      paymentType: paymentType,
    };
    let type = await putPaymentType(data);

    if (type) {
      window.location.reload();
    }
  };

  const updateManualPayment = async (paymentStep = "event2paymentType") => {
    setLoading(true);
    const transactionId = document.getElementById("transactionId").value;
    let data = {
      applicationID: _id,
      paymentStep: paymentStep,
      event2transactionId: transactionId,
    };
    let updateTransactionId = await updatePaymentDetails(data);

    const { CASHSALE2, CUSTOMERDEPOSIT, VENDORBILL2 } = netsuiteField;
    await netSuiteUpsertRecords(_id, "CASHSALE2", CASHSALE2);
    await netSuiteUpsertRecords(_id, "CUSTOMERDEPOSIT", CUSTOMERDEPOSIT);
    await netSuiteUpsertRecords(_id, "VENDORBILL2", VENDORBILL2);

    if (updateTransactionId) {
      await approvepayment(_id);
      await putNextStatus(_id, 1);
      window.location = `${REACT_APP_URL}/compiledAgreement/${_id}`;
    }
  };

  const updatePaymentTransiction = async (paymentStep = "event2paymentType") => {
    setLoading(true);
    const transactionId = document.getElementById("transactionId").value;
    let data = {
      applicationID: _id,
      paymentStep: paymentStep,
      event2transactionId: transactionId,
    };
    let updateTransactionId = await updatePaymentDetails(data);

    const { CASHSALE2, CUSTOMERDEPOSIT, VENDORBILL2 } = netsuiteField;
    await netSuiteUpsertRecords(_id, "CASHSALE2", CASHSALE2);
    await netSuiteUpsertRecords(_id, "CUSTOMERDEPOSIT", CUSTOMERDEPOSIT);
    await netSuiteUpsertRecords(_id, "VENDORBILL2", VENDORBILL2);

    if (updateTransactionId) {
      await approvepayment(_id);
      await putNextStatus(_id, 1);
      window.location = `${REACT_APP_URL}/compiledAgreement/${_id}`;
    }
  };

  const updateTransictionId = () => {
    setEditPaymentNotes(true);
  };

  const validatepayment = async () => {
    setLoading(true);
    await approvepayment(_id);
    window.location = `${REACT_APP_URL}/compiledAgreement/${_id}`;
  };

  const updateTransictionNumber = async () => {
    setLoading(true);
    const transactionIds = document.getElementById("transactionIds").value;
    let data;
    if (emails.includes(contact?.email) && !transactionIds) {
      data = {
        applicationID: _id,
        event2transactionNumber: "",
      };
    } else {
      data = {
        applicationID: _id,
        event2transactionNumber: transactionIds,
      };
    }

    let updateTransactionIds = await updatePaymentDetails(data);

    if (updateTransactionIds) {
      window.location = `${REACT_APP_URL}/compiledAgreement/${_id}`;
    }
  };

  const assignPayment = async (contact) => {
    let linkUrl = REACT_APP_URL + `/compiledAgreement/${_id}`;
    let data = {
      applicationID: _id,
      LinkUrl: linkUrl,
      agreementType: "compilePayment",
    };
    if (contact) {
      const { name, email } = contact;
      data["name"] = name;
      data["email"] = email;
    }

    const value = application.agreement.compiled.find((data) => data.action === "Sign and Paid");
    const otherpayment = value?.paymentby || {};
    if (otherpayment) {
      data = { ...otherpayment, ...data };
    }

    await assignPaymentApi(data);
    window.location.reload();
  };

  useEffect(() => {
    getApplicantEmail_ACH_Number_Not_Exist();
  }, []);

  useEffect(() => {
    if (!application?.event2validatedOn && application.applicationStage > 18) {
      setOldApplication(true);
    }
  }, [application]);

  useEffect(() => {
    let arrayOfnesuite = [];
    Netsuite.sections.forEach((section) => {
      section.questions.forEach((val) => {
        if (val.compilePayment === "true") {
          arrayOfnesuite.push(val);
        }
      });
    });
    const array = arrayOfnesuite.map((section) => {
      return section?.recordName;
    });
    setRecords(array);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const promises = records.map(async (record) => {
          const res = await getNetsuiteInitialValue(_id, record);
          const response = res?.data?.data;
          return { [record]: response };
        });
        const results = await Promise.all(promises);
        const mergedResults = results.reduce((acc, result) => ({ ...acc, ...result }), {});

        setNetsuiteValue((initialValue) => ({
          ...initialValue,
          ...mergedResults,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [records, _id]);

  return (
    <div className="review-page">
      <PaymentInformation />
      {!oldApplication && (
        <>
          <Payment updatePaymentType={updatePaymentType} assignPayment={assignPayment} emails={emails} updateTransictionNumber={updateTransictionNumber} />

          <AuthorizeAgreementFee
            updateManualPayment={updateManualPayment}
            editPaymentNotes={editPaymentNotes}
            updatePaymentTransiction={updatePaymentTransiction}
            updateTransictionId={updateTransictionId}
            validatepayment={validatepayment}
            netsuiteField={netsuiteField}
            setNetsuiteValue={setNetsuiteValue}
          />
        </>
      )}

      {oldApplication && <OldApplication updateTransictionId={updateTransictionId} editPaymentNotes={editPaymentNotes} updatePaymentTransiction={updatePaymentTransiction} />}
    </div>
  );
};

const Payment = ({ updatePaymentType, assignPayment, emails, updateTransictionNumber }) => {
  const [me, setMe] = useState(true);
  const [errorTrans, setErrorTrans] = useState("");

  const application = useApplication();
  const { roleName = "", contact = {} } = useUser() || {};

  const { applicationStage, event2transactionNumber, event2transactionNumberDate, event2paymentType, event2paymentOn, _id, payment, event2validatedOn, agreement } = application;
  const { event2StripeResponse = [] } = payment;

  const { compiled = [] } = agreement || {};

  const { license_fee, riskFinanceFee, nsr_application_fee, transactionFee, totalPaymentFee, totalOfflinePaymentFee } = getCompilePaymentFee(application);

  const paymentByCompiled = compiled.find((each) => each.action === "Sign and Paid" && each.role === "applicant");
  const paymentby = paymentByCompiled?.paymentby;

  const validatedStripeResponse = event2StripeResponse.find((each) => each?.validated === true);
  const validated = validatedStripeResponse?.validated || false;

  const paidStripeResponse = event2StripeResponse.find((each) => each?.payment_status === "paid");
  const payment_status = paidStripeResponse ? true : false;
  const payment_intent = paidStripeResponse?.payment_intent || "";

  const handleChange = (event) => {
    setErrorTrans(event.target.value);
  };

  const isApplicant = () => roleName === "applicant";
  const isNotApplicant = () => roleName !== "applicant";
  const isAccountant = () => roleName === "Approving Accountant";

  const isOfflinePayment = () => event2paymentType === "Check" || event2paymentType === "ACH";
  const isOnlinePayment = () => payment_status || event2paymentType === "Stripe";
  const isPaymentNotStarted = () => applicationStage === 17 && !isOfflinePayment() && !isOnlinePayment();
  const isPaymentNotAssigned = () => applicationStage === 17 && !paymentby?.email;
  const isPaymentValidated = () => validated || event2validatedOn;
  const isCheckPayment = () => event2paymentType === "Check";
  const isACHPayment = () => event2paymentType === "ACH";
  const isNotPaymentValidated = () => !event2transactionNumber && event2transactionNumber !== "";
  const isInStage = (stage) => applicationStage === stage;
  const paymentInWaiting = () => paymentby?.status === "waiting";
  const isNotPaymentApplicant = () => contact?.email !== paymentby?.email;
  const isPaymentApplicant = () => contact?.email === paymentby?.email;

  return (
    <div className="accordion print" id="accordionExample1">
      <div className="accordion-item mb-4">
        <div className="card">
          <div className="card-body p-0">
            <h2 className="accordion-header border-bottom" id="PaymentSec">
              <button
                className=" accordion-button py-4 px-4 d-flex justify-content-between "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Payment1"
                aria-expanded="false"
                aria-controls="Payment1"
              >
                Payment
              </button>
            </h2>
            <div id="Payment1" className="accordion-collapse show " aria-labelledby="PaymentSec" data-bs-parent="#accordionExample1">
              <div className="accordion-body p-0">
                <div className="inner-form  ">
                  <div className="container-fluid">
                    <div className="row">
                      {isNotApplicant() && isPaymentNotStarted() && (
                        <div className="col-lg-12 p-2">
                          <div className="alert alert-warning mt-3 text-dark" role="alert">
                            Applicant has not made the payment yet.
                          </div>
                        </div>
                      )}

                      {/* Offline payment note */}
                      {isApplicant() && isInStage(17) && isOfflinePayment() && (
                        <div className="col-lg-12">
                          <div className="inner-form">
                            <div className="card mb-4 mt-2 bg-warning">
                              <div className="card-header bg-transparent d-print-none">
                                <h2 className="mb-0 text-white">
                                  <i className="fas fa-money-bill"></i> You have chosen to pay offline
                                </h2>
                              </div>
                              <div className="card-body">
                                {isCheckPayment() && (
                                  <div className="messages">
                                    <p className="d-print-none">
                                      We have notified the NCRR accounting team of your preference. This application will be on hold until the payment is processed. Please send your check to mailing
                                      address:
                                    </p>
                                    <strong>North Carolina Railroad Company</strong>
                                    <p className="d-print-none">
                                      Attn: Accounting Dept <br />
                                      2809 Highwoods Blvd <br />
                                      Raleigh, NC 27604
                                    </p>
                                    <p className="d-print-none">
                                      <strong> NOTE : </strong>
                                      Attach{" "}
                                      <a className="text-primary fw-bold" onClick={() => window.print()}>
                                        Print
                                      </a>
                                      of this payment page and send it along with your checks. Please send email with your check details at <span className="fw-bold">ar@ncrr.com</span>
                                    </p>
                                  </div>
                                )}
                                {isACHPayment() && (
                                  <>
                                    <div className="messages d-print-none">
                                      <p>
                                        We have notified the NCRR accounting team of your preference. This application will be on hold until the ACH is processed. Please reference the Application
                                        Number and remit to:
                                      </p>
                                      <p className="d-print-none">
                                        ACH Routing#: 053101121 <br />
                                        ACH Account#: 0005205335511
                                        <br />
                                        Bank: Truist
                                      </p>
                                    </div>
                                  </>
                                )}

                                {isCheckPayment() && (
                                  <div className="messages">
                                    <p>Acknowledgement Done for Check.</p>
                                  </div>
                                )}

                                {isACHPayment() && (
                                  <div className="messages">
                                    <p>Acknowledgement Done for ACH.</p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="col-lg-12">
                        {isApplicant() && isInStage(17) && (
                          <>
                            <div className="card-body pb-0">
                              {/* me and Other radio */}
                              {isPaymentNotStarted() && (
                                <div className="mb-3">
                                  <div className="mb-2 mt-2">
                                    <div className="d-flex align-items-center mb-2">
                                      <div className="form-check">
                                        <input className="form-check-input SignByMe" onClick={() => setMe(true)} type="radio" checked={me} name="flexRadioDefault" id="Signedyes" />
                                        <label className="form-check-label" htmlFor="Signedyes">
                                          Me
                                        </label>
                                      </div>
                                      <div className="form-check ms-4">
                                        <input className="form-check-input SignByOthers" onClick={() => setMe(false)} checked={!me} type="radio" name="flexRadioDefault" id="signedno" />
                                        <label className="form-check-label" htmlFor="signedno">
                                          Others
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Payment waiting for Other applicant */}
                              {!me && <SelectForPayment assignPayment={assignPayment} />}

                              {/* Payment waiting for Other applicant */}
                              {paymentInWaiting() && isNotPaymentApplicant() && (
                                <>
                                  <div className="alert alert-warning mt-3 text-dark" role="alert">
                                    waiting for {paymentby?.name} to pay the application fee.
                                  </div>
                                  <div
                                    className="my-3 text-primary"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={assignPayment}
                                  >
                                    <u>Resend Email</u>
                                  </div>
                                </>
                              )}

                              {/* Payment waiting for Payment applicant PaymentCard */}
                              {me && isPaymentNotStarted() && (!paymentby || isPaymentApplicant()) && (
                                <PaymentCard
                                  totalAmountForStripe={Number(license_fee) + Number(riskFinanceFee) + Number(nsr_application_fee) + Number(transactionFee)}
                                  totalAmountForOffline={Number(license_fee) + Number(riskFinanceFee) + Number(nsr_application_fee)}
                                  updatePaymentType={updatePaymentType}
                                  _id={_id}
                                />
                              )}
                            </div>
                          </>
                        )}

                        {/* Payment Complete */}
                        {/* And Not Yet Validated */}
                        {isOfflinePayment() && (
                          <div className="field-col mt-4 ml-2">
                            <h3>Payment Method </h3>
                            <p className="d-flex justify-content-between p-0 align-items-center">
                              <span>
                                Payment Type: <strong>{event2paymentType || "Stripe"}</strong>
                                {isPaymentValidated() && (
                                  <>
                                    <br /> Payment Received: <strong>${isOfflinePayment() && totalPaymentFee}</strong>
                                  </>
                                )}
                                <br />
                                {isNotPaymentValidated() && isACHPayment() && isApplicant() && (
                                  <div className="mb-4">
                                    <br />
                                    <h3>ACH Transaction Trace Number</h3>
                                    <input required className="form-control" id="transactionIds" name="transactionIds" onChange={handleChange} value={errorTrans} />
                                    <span className="text-danger ps-2">
                                      <span>{emails.includes(contact.email) ? "" : errorTrans === "" ? "* This field is required" : null}</span>
                                    </span>
                                  </div>
                                )}
                                {event2transactionNumber && event2paymentType === "ACH" && (
                                  <>
                                    ACH Transaction Trace Number : <strong>{event2transactionNumber && event2transactionNumber}</strong>
                                  </>
                                )}
                              </span>
                            </p>
                          </div>
                        )}

                        {isOnlinePayment() && (
                          <div className="field-col mt-4 ml-2">
                            <h3>Payment Method </h3>
                            <p className="d-flex justify-content-between p-0 align-items-center">
                              <span>
                                Payment Type: <strong>Stripe</strong>
                                <br /> Payment Received: <strong>${roleName === "applicant" ? totalPaymentFee : totalOfflinePaymentFee}</strong>
                                <br />
                              </span>
                              {isAccountant() && (
                                <a href={`${PAYMENT_LINK}${payment_intent}`} target="_blank" className="btn btn-link p-2">
                                  Payment Intent
                                </a>
                              )}
                            </p>
                          </div>
                        )}

                        {isOfflinePayment() && isApplicant() && !isPaymentValidated() && (
                          <div className="field-col mt-4 ml-2">
                            <h3>Other Payment Methods?</h3>
                            <PaymentCard
                              _id={_id}
                              totalAmountForStripe={Number(license_fee) + Number(riskFinanceFee) + Number(nsr_application_fee) + Number(transactionFee)}
                              totalAmountForOffline={Number(license_fee) + Number(riskFinanceFee) + Number(nsr_application_fee)}
                              updatePaymentType={updatePaymentType}
                              paymentType={event2paymentType}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Footer */}
                {/* Non ACH After Payment */}

                {!event2transactionNumber && event2transactionNumber !== "" && event2paymentOn && event2paymentType !== "ACH" && (
                  <div className="card-footer py-0 justify-content-center bg-success">
                    <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                      <span className="d-flex justify-content-between align-items-center">
                        <span>
                          <i className="fal fa-check"></i>Paid
                        </span>
                        <span>By Applicant on {event2paymentOn && <small> {event2paymentOn}</small>}</span>
                      </span>
                    </a>
                  </div>
                )}
                {event2paymentType === "ACH" && !event2transactionNumber && event2transactionNumber !== "" && roleName === "applicant" && (
                  <div className="card-footer py-0 justify-content-center bg-primary">
                    <a
                      className={`${errorTrans !== "" || emails.includes(contact?.email) ? "d-block text-white py-4 px-0" : "d-block text-white py-4 px-0 disabled"}`}
                      tabIndex="-1"
                      role="button"
                      onClick={updateTransictionNumber}
                    >
                      <span className="d-flex justify-content-between align-items-center">
                        <span>
                          {"Submit"} <i className="fal fa-chevron-right ms-2"></i>
                        </span>
                      </span>
                    </a>
                  </div>
                )}

                {!event2transactionNumber && event2transactionNumber !== "" && roleName !== "applicant" && event2paymentType === "ACH" && (
                  <div
                    className="card-footer justify-content-center "
                    style={{
                      backgroundColor: "rgb(233 237 241)",
                    }}
                  >
                    Awaiting for Applicant
                    <i className="fal fa-chevron-right ms-2"></i>
                  </div>
                )}
                {(event2transactionNumber || event2transactionNumber === "") && (
                  <div className="card-footer py-0 justify-content-center bg-success">
                    <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                      <span className="d-flex justify-content-between align-items-center">
                        <span>
                          <i className="fal fa-check"></i> {event2transactionNumberDate && event2transactionNumberDate ? "Paid" : "Paid"}
                        </span>
                        <span>By Applicant on {event2transactionNumberDate && <small> {event2transactionNumberDate}</small>}</span>
                      </span>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddContactModal assignPayment={assignPayment} />
    </div>
  );
};

const PaymentCard = ({ totalAmountForStripe, totalAmountForOffline, updatePaymentType, _id, paymentType = "" }) => {
  return (
    <form action={`${API_URL}/create-checkout-session`} method="POST">
      <div className="pay-card">
        <div className="divider position-relative">
          <hr />
          <p className="divider-txt">
            <small className="text-muted">Pay with card</small>
          </p>
        </div>
        <button type="submit" className="btn btn-block btn-primary">
          Pay with Card $<span>{totalAmountForStripe.toFixed(2)}</span>
          <small className="d-block">3.01% convenience charge applicable</small>
        </button>
      </div>
      <div className="pay-card py-3">
        <div className="divider position-relative">
          <hr />
          <p className="divider-txt">
            <small className="text-muted">Pay Offline</small>
          </p>
        </div>
        {totalAmountForOffline && (
          <>
            {paymentType !== "Check" && (
              <a
                className="btn btn-block btn-outline-primary ms-0"
                href="javascript:void(0)"
                onClick={() => {
                  updatePaymentType("event2paymentType", "Check");
                }}
              >
                Pay with Check $<span>{totalAmountForOffline}</span>
              </a>
            )}

            {paymentType !== "ACH" && (
              <a
                className="btn btn-block btn-outline-primary ms-0"
                href="javascript:void(0)"
                onClick={() => {
                  updatePaymentType("event2paymentType", "ACH");
                }}
              >
                Pay with ACH $<span>{totalAmountForOffline}</span>
              </a>
            )}
          </>
        )}
      </div>
      <input type="hidden" name="product_quantity" value="1" />
      <input type="hidden" name="success_url" value={`${REACT_APP_URL}/agreementPaymentWaiting/${_id}?success=true`} />
      <input type="hidden" name="applicationID" value={_id} />
      <input
        type="hidden"
        name="cancel_url"
        value={`${REACT_APP_URL}/compiledAgreement/${_id}?canceled=true
`}
      />
    </form>
  );
};

const SelectForPayment = ({ assignPayment }) => {
  const application = useApplication();
  const user = useUser();
  const [facilityContacts, setFacilityContacts] = useState([]);
  const [consultantContacts, setConsultantContacts] = useState([]);
  const [applicationContacts, setApplicationContacts] = useState([]);

  const [selectedContact, setSelectedContact] = useState(null);

  const getFacilityContacts = async (companyID, applicantDetails) => {
    const res = await getContactsByCompanyId(companyID);
    if (res.data.status) {
      const contacts = res.data.data;
      const { primaryContact, billingContact, consultantContact, signatoryContact, engineeringContact } = applicantDetails;
      const filteredContacts = contacts.filter((each) => {
        if (each.id === primaryContact?.id) {
          return false;
        }
        if (each.id === billingContact?.id) {
          return false;
        }
        if (each.id === consultantContact?.id) {
          return false;
        }
        if (each.id === engineeringContact?.id) {
          return false;
        }
        if (each.id === signatoryContact?.id) {
          return false;
        }
        return true;
      });
      setFacilityContacts(filteredContacts);
    }
  };

  const getConsultantContacts = async (companyID, applicantDetails) => {
    const res = await getContactsByCompanyId(companyID);
    if (res.data.status) {
      const contacts = res.data.data;
      const { primaryContact, billingContact, consultantContact, signatoryContact, engineeringContact } = applicantDetails;
      const filteredContacts = contacts.filter((each) => {
        if (each.id === primaryContact?.id) {
          return false;
        }
        if (each.id === billingContact?.id) {
          return false;
        }
        if (each.id === consultantContact?.id) {
          return false;
        }
        if (each.id === engineeringContact?.id) {
          return false;
        }
        if (each.id === signatoryContact?.id) {
          return false;
        }
        return true;
      });
      setConsultantContacts(filteredContacts);
    }
  };

  const getApplicationContacts = (applicantDetails) => {
    const { primaryContact, billingContact, consultantContact, signatoryContact, engineeringContact } = applicantDetails;
    const applicationContacts = [];

    if (billingContact) {
      if (!applicationContacts.some((each) => each.id === billingContact.id)) {
        applicationContacts.push(billingContact);
      }
    }
    if (primaryContact) {
      if (!applicationContacts.some((each) => each.id === primaryContact.id)) {
        applicationContacts.push(primaryContact);
      }
    }
    if (consultantContact) {
      if (!applicationContacts.some((each) => each.id === consultantContact.id)) {
        applicationContacts.push(consultantContact);
      }
    }
    if (signatoryContact) {
      if (!applicationContacts.some((each) => each.id === signatoryContact.id)) {
        applicationContacts.push(signatoryContact);
      }
    }
    if (engineeringContact) {
      if (!applicationContacts.some((each) => each.id === engineeringContact.id)) {
        applicationContacts.push(engineeringContact);
      }
    }
    setApplicationContacts(applicationContacts);
  };

  useEffect(() => {
    if (application) {
      const { consultantId, facilityId, applicantDetails } = application;
      if (consultantId) {
        getConsultantContacts(consultantId, applicantDetails);
      }
      if (facilityId) {
        getFacilityContacts(facilityId, applicantDetails);
      }
      if (applicantDetails) {
        getApplicationContacts(applicantDetails);
      }
    }
  }, [application]);

  if (!application || !user) {
    return <DashBoardLoading />;
  }

  const handleSelectChange = (id) => {
    let contact = null;

    if (id === "NEW") {
      setSelectedContact(id);
      return;
    }

    if (!contact) {
      contact = applicationContacts.find((each) => each.id === id);
    }

    if (!contact) {
      contact = facilityContacts.find((each) => each.id === id);
    }
    if (!contact) {
      contact = consultantContacts.find((each) => each.id === id);
    }

    setSelectedContact(contact);
  };

  return (
    <div className="card bg-secondary border-0">
      <div id="div1" className="card-body p-3">
        <div>
          <div className="input-group" role="group">
            <select className="form-select" id="search-select" onChange={(e) => handleSelectChange(e.target.value)} aria-label="Default select example">
              <option value="" selected disabled>
                Select contact for Payment
              </option>
              <optgroup
                label="Application Contacts"
                style={{
                  color: "gray",
                }}
              >
                {applicationContacts.map((each) => {
                  return <option value={each?.id}>{`${each.name} (${each?.email})`}</option>;
                })}
              </optgroup>
              {facilityContacts.length > 0 && (
                <optgroup
                  label="Facility Contacts"
                  style={{
                    color: "gray",
                  }}
                >
                  {facilityContacts.map((each) => {
                    return <option value={each?.id}>{`${each.name} (${each?.email})`}</option>;
                  })}
                </optgroup>
              )}
              {consultantContacts.length > 0 && (
                <optgroup
                  label="Engineering Contacts"
                  style={{
                    color: "gray",
                  }}
                >
                  {consultantContacts.map((each) => {
                    return <option value={each?.id}>{`${each.name} (${each?.email})`}</option>;
                  })}
                </optgroup>
              )}
              <optgroup
                label="New"
                style={{
                  color: "gray",
                }}
              >
                <option value={"NEW"}>Add New Contact</option>
              </optgroup>
            </select>
            {selectedContact && selectedContact !== "NEW" && (
              <div id="hidden_div" className="dropdown-content-bond">
                <button
                  type="button"
                  className="btn btn-success"
                  style={{
                    borderRadius: "0 6px 6px 0",
                    height: "100%",
                    paddingBottom: "0.7rem !important",
                    paddingTop: " 0.75rem !important",
                  }}
                  onClick={() => assignPayment(selectedContact)}
                >
                  Send
                </button>
              </div>
            )}
            {selectedContact === "NEW" && (
              <div id="hidden_div2" className="dropdown-content-bond">
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop22"
                  className="btn btn-success"
                  style={{
                    borderRadius: "0 6px 6px 0",
                    height: "100%",
                    paddingBottom: "0.7rem !important",
                    paddingTop: " 0.75rem !important",
                  }}
                >
                  Add
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const AddContactModal = ({ assignPayment }) => {
  const application = useApplication();
  const user = useUser();
  const [contact, setContact] = useState({
    name: "",
    email: "",
    role: "",
  });
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (user) {
      const { company } = user;
      if (company) {
        const { companyType } = company;
        if (companyType === "FACILITY") {
          const roles = [
            {
              name: "role",
              value: "Primary Representative",
              label: "Primary Representative",
            },
            {
              name: "role",
              value: "Billing Representative",
              label: "Billing Representative",
            },
          ];
          setRoles(roles);
        }

        if (companyType === "CONSULTANT") {
          const roles = [
            {
              name: "role",
              value: "Primary Representative",
              label: "Primary Representative",
            },
            {
              name: "role",
              value: "Billing Representative",
              label: "Billing Representative",
            },
            {
              name: "role",
              value: "Engineering Representative",
              label: "Engineering Representative",
            },
          ];
          setRoles(roles);
        }
      }
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!contact.name || !contact.email || !contact.role) {
      return;
    }

    const { facilityId, consultantId } = application;
    const { name, email, role } = contact;

    const data = {
      name,
      jobTitle: "",
      email,
      phone: "",
      role: [role],
      default: false,
      address: {
        address: "",
        city: "",
        state: "",
        zip: "",
        poBox: "",
        country: "United States",
      },
    };

    if (role === "Primary Representative" || role === "Billing Representative") {
      data["companyId"] = facilityId;
    }

    if (role === "Engineering Representative") {
      data["companyId"] = consultantId;
    }

    await createContact(data);
    await assignPayment(data);
  };

  const handleChange = (e) => {
    if (e?.name) {
      setContact({ ...contact, [e.name]: e.value });
      return;
    }
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  return (
    <div className="modal fade" id="staticBackdrop22" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <label htmlFor="">Add Contact</label>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input required={true} type="text" name="name" onChange={handleChange} className="form-control mb-3" id="name" placeholder="Enter Name" />
              <label htmlFor="email" className="form-label">
                Email address
              </label>
              <input required={true} type="email" name="email" onChange={handleChange} className="form-control" id="email" placeholder="Enter Email Address" />
              <div className="mt-3">
                <label className="form-label d-flex justify-content-between">Role</label>
                <Select placeholder="Select Roles" required={true} onChange={handleChange} options={roles} />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
              <button type="submit" className="btn btn-success">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CompiledPayment;
